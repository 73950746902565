import { NoticePeriodTable } from '@components/notice-period-table/notice-period-table.component'
import { Alert, Box, Button, Typography } from '@mui/material'
import { getNoticePeriods } from '@remoteam-front/services'
import { Country } from '@remoteam-front/types/v2'
import { DotWave } from '@uiball/loaders'
import { useQuery } from 'react-query'

type Props = {
  country: Country
  onNext(): void
}

export const NoticePeriod = ({ country, onNext }: Props) => {
  const noticePeriodsQuery = useQuery([getNoticePeriods.key], {
    enabled: !!country.id,
    queryFn: () => getNoticePeriods.fetch(country.id),
  })

  if (noticePeriodsQuery.isLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <DotWave />
      </Box>
    )
  }

  if (noticePeriodsQuery.data) {
    return (
      <Box display="flex" flexDirection="column" gap={4}>
        <Alert
          severity="info"
          sx={{
            alignSelf: !onNext ? 'flex-start' : 'center',
            marginBottom: 6,
          }}
        >
          {noticePeriodsQuery.data.length
            ? 'If this information is not up to date according to your knowledge,please contact us.'
            : `There is no information available about the notice period for ${country.name}`}
        </Alert>

        {noticePeriodsQuery.data.length ? (
          <>
            <Typography variant="body2">
              Notice period in {country.name}:
            </Typography>

            <NoticePeriodTable data={noticePeriodsQuery.data} />
          </>
        ) : null}

        {onNext ? (
          <Box alignSelf="center">
            <Button variant="contained" onClick={onNext}>
              Next
            </Button>
          </Box>
        ) : null}
      </Box>
    )
  }

  return null
}
