import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import ModalSide from '@atoms/ModalSide/ModalSide'
import Typography from '@atoms/Typography/Typography'
import { useApp } from '@core/context'
import { useToast } from '@core/hooks/useErrorNotification'
import { androidIcon, iosIcon } from '@core/icons/icons'
import { verifyMFA } from '@services/mfa.service'
import React, { useState } from 'react'
import OtpInput from 'react-otp-input'
import QRCode from 'react-qr-code'
import { useMutation } from 'react-query'
import styled from 'styled-components'
import { useBoolean } from 'usehooks-ts'

import { useAuth } from '../../../AuthProvider'
import { copyCodes, SaveRecoveryKeys } from './save-recovery-keys'

export const StyledInput = styled.input`
  border-radius: 8px;
  width: 3rem !important;
  height: 4rem;
  font-size: 2rem;
  border: 1px solid rgba(0, 0, 0, 0.3);

  :focus {
    outline: none;
    border-color: #40b84c;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`

export const EnablingMfaModal = ({
  open,
  onClose,
  recovery_codes,
  barcode_uri,
  secret,
  onKeyDown,
}) => {
  const { successAlert, failedAlert } = useToast()
  const { tokens } = useAuth()
  const { refetchProfile } = useApp()
  const [authCode, setAuthCode] = useState('')
  const [authCodeError, setAuthCodeError] = useState('')
  const enabledMFA = useBoolean(false)
  const QRVisible = useBoolean(true)

  const refresh_token = tokens ? tokens.refresh_token : ''

  const { mutate: mutateVerifyMFA, isLoading: isLoadingVerifyMFA } =
    useMutation('verifyMFA', verifyMFA, {
      onSuccess: () => {
        enabledMFA.setTrue()
        successAlert('Congratulations, 2FA is now enabled!')
      },
      onError: (error) => {
        const { message } = error.response.data.errors[0]
        if (message.includes('Invalid otp_code'))
          setAuthCodeError(
            'The code you entered is incorrect, please try again'
          )
        else failedAlert(message)
      },
    })

  const handleClose = () => {
    if (enabledMFA.value) {
      refetchProfile()
      setAuthCodeError('')
      QRVisible.setTrue()
      setAuthCode('')
      enabledMFA.setFalse()
      onClose()
    } else {
      setAuthCodeError('')
      QRVisible.setTrue()
      setAuthCode('')
      onClose()
    }
  }

  const handleAuthCodeComplete = () => {
    if (authCode.length === 6) mutateVerifyMFA({ otp: authCode, refresh_token })
    else setAuthCodeError('The code must contain 6 digits.')
  }

  const handleOK = () => {
    if (enabledMFA.value) {
      copyCodes(recovery_codes)
      successAlert('Recovery code copied to clipboard')
      handleClose()
    } else handleAuthCodeComplete()
  }

  return open ? (
    <ModalSide
      title="Enable Two-factor authentication (2FA)"
      okText={enabledMFA.value ? 'Copy code & close' : 'Next step'}
      onClose={handleClose}
      onOk={handleOK}
      okButtonProps={{ loading: isLoadingVerifyMFA, id: 'next-step' }}
      onKeyDown={onKeyDown}
    >
      {enabledMFA.value ? (
        <SaveRecoveryKeys recoveryCodes={recovery_codes} />
      ) : (
        <div className="d-flex flex-column align-items-center">
          <Typography>
            Please scan the QR code using your authorization application
          </Typography>
          <div
            style={{ height: 160 }}
            className="my-5 d-flex align-items-center justify-content-center"
          >
            {QRVisible.value ? (
              <QRCode value={barcode_uri} size={160} />
            ) : (
              <Typography
                className="heading_semibold__16"
                style={{ fontSize: '24px' }}
              >
                {secret}
              </Typography>
            )}
          </div>

          <Typography className="mb-3">
            or enter the following code in your authorization application
          </Typography>
          <Button
            data-testid="enabling-mfa-modal-A735E8"
            size="small"
            priority="secondary"
            onClick={QRVisible.toggle}
            style={{ width: 205 }}
          >
            {QRVisible.value ? 'Show me code' : 'Show me QR'}
          </Button>
          <Typography className="mt-4 mb-3">
            Please enter the 6-digit numeric code from your authenticator app
          </Typography>
          <div className="d-flex flex-column gap-3">
            <OtpInput
              value={authCode}
              onChange={(value) => {
                setAuthCodeError('')
                setAuthCode(value)
              }}
              numInputs={6}
              renderInput={(props) => (
                <StyledInput
                  {...props}
                  onKeyDown={(e) => {
                    if (!(e.ctrlKey && e.key === 'v')) {
                      props.onKeyDown(e)
                    }
                  }}
                />
              )}
              inputType="number"
              containerStyle={{ display: 'flex', columnGap: '16px' }}
              shouldAutoFocus
            />
            {authCodeError && (
              <Typography className="color_red align-self-center">
                {authCodeError}
              </Typography>
            )}
          </div>
          <div className="d-flex flex-column align-items-center justify-content-center mt-4">
            <Typography>Download Google Authenticator:</Typography>
            <div className="d-flex mt-3">
              <Button
                data-testid="enabling-mfa-modal-E68A00"
                size="small"
                priority="secondary"
                style={{ width: 164, marginRight: 12 }}
                onClick={() => {
                  window.open(
                    'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2',
                    '_blank'
                  )
                }}
              >
                <Icon icon={androidIcon} className="mr-2" />
                Android
              </Button>
              <Button
                data-testid="enabling-mfa-modal-7442F2"
                size="small"
                priority="secondary"
                style={{ width: 164 }}
                onClick={() => {
                  window.open(
                    'https://apps.apple.com/us/app/google-authenticator/id388497605',
                    '_blank'
                  )
                }}
              >
                <Icon icon={iosIcon} className="mr-2" />
                IOS
              </Button>
            </div>
          </div>
        </div>
      )}
    </ModalSide>
  ) : null
}
