import { TEMPLATE_TYPES } from '@core/constants'
import { capitalizeFirstLetter, mapEnum } from '@core/utils'
import { DotWave } from '@uiball/loaders'
import moment from 'moment'
import React from 'react'
import { MdOutlineInsertDriveFile } from 'react-icons/md'
import styled from 'styled-components'

import AgreementDetail from './agreement-detail/agreement-detail.component'

const FileIcon = styled(MdOutlineInsertDriveFile)`
  color: #40b84c;
  margin-right: 8px;
`

export const transformTemplates = (templatesResponse) => {
  if (!templatesResponse || !templatesResponse.results) return []
  return templatesResponse.results.map((item) => {
    const { id, title, status, modified_at, template_type } = item
    return {
      id,
      template_type: capitalizeFirstLetter(mapEnum(template_type)),
      name: (
        <>
          <FileIcon />
          {title}
        </>
      ),
      status,
      updated: moment(modified_at).format('DD MMM YYYY, HH:mm'),
      original: item,
    }
  })
}

const agreementDocumentType = (type) => {
  switch (type) {
    case TEMPLATE_TYPES.JOB_CONTRACT:
      return 'Employment agreement'
    case TEMPLATE_TYPES.APPENDIX:
      return 'MSA appendix'
    case TEMPLATE_TYPES.MSA:
      return 'MSA'
    case TEMPLATE_TYPES.SALARY_CHANGE:
      return 'Salary change'
    case TEMPLATE_TYPES.CONTRACT_TERMINATION:
      return 'Contract termination'
    default:
      return ''
  }
}

export const transformAgreements = (
  agreementsResponse,
  agreementDetail,
  isLoading
) => {
  if (!agreementsResponse || !agreementsResponse.results) return []
  return agreementsResponse.results.map((item) => {
    const basicAgreement = {
      id: item.id,
      signature: item.signature,
      name: (
        <>
          <FileIcon />
          {item.title}
        </>
      ),
      signed: moment(item?.job_agreement?.date_of_signature).format(
        'DD MMM YYYY'
      ),
      company: item?.job_agreement?.contract?.company.name,
      employee: item?.job_agreement?.contract?.full_name,
      status: item.state,
      agreement_document_type: agreementDocumentType(
        item.agreement_document_type
      ),
      pending: item.awaiting_signature_count,
      created: moment(item.created_at).format('DD MMM YYYY, HH:mm'),
      original: item,
      collapseContent: isLoading ? (
        <div
          className="d-flex w-100 align-items-center justify-content-center"
          style={{ height: 200 }}
        >
          <DotWave size={32} speed={1} color="black" />
        </div>
      ) : (
        <AgreementDetail data={agreementDetail} />
      ),
    }
    if (item.agreement_document_type === TEMPLATE_TYPES.APPENDIX)
      return {
        ...basicAgreement,
        company: item?.appendix_agreement?.company?.name,
      }
    return basicAgreement
  })
}
