import React from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

const Tabs = styled.ul`
  display: flex;
  list-style: none;
  gap: 1rem;
  padding: 0;
  border-bottom: 1px solid #dfdfdf;
`

const Tab = styled.li`
  a {
    color: #878787;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    text-decoration: none;
    display: inline-block;
    padding: 0.5rem 0.75rem;

    &.active {
      border-bottom: 1px solid #137547;
      color: #121212;
    }
  }
`

export const TabLinks = ({ items }) => {
  const { path, url, params } = useRouteMatch()

  return (
    <Tabs>
      {items?.map(({ title, link }) => (
        <Tab key={link}>
          <NavLink to={url + link} activeClassName="active">
            {title}
          </NavLink>
        </Tab>
      ))}
    </Tabs>
  )
}
