import { Api } from '@api/Api'

export const fetchCountriesIdBurden = async ({
  countryId,
  yearly_salary,
  currency,
  company,
}) => {
  const { data } = await Api.post(`countries/${countryId}/calculate-burden/`, {
    yearly_salary,
    currency,
    company,
  })
  return data
}

export const fetchCountryPaidLeavesById = async (id) => {
  const { data } = await Api.get(`country/${id}/paid-leaves/`)
  return data
}

export const fetchCountrySpecificPaidById = async (id) => {
  const { data } = await Api.get(`country/${id}/specific-paid-leaves/`)
  return data
}

export const fetchGetProvinces = async (id) => {
  const { data } = await Api.get(`regions/?country=${id}&limit=1000`)
  return data
}

export const fetchOccupations = async (country_id) => {
  const { data } = await Api.get(`occupations/?country_id=${country_id}`)
  return data
}
