import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import UploadButton from '@atoms/UploadButton'
import { attachFile, deleteIcon } from '@core/icons/icons'

import { Styled } from './time-off-modal/time-off-modal.styles'

function AttachmentFormItem({
  timeOff,
  doc,
  handleUpload,
  handleRemove,
  handleTimeOffAttachmentDelete,
}) {
  if (!timeOff) return false

  if (timeOff.attached_document) {
    return (
      <div className="d-flex justify-content-between align-items-center mt-3">
        <Typography as="span" className="text_medium__14">
          <Icon icon={attachFile} />
          <a href={timeOff?.attached_document} target="_blank" rel="noreferrer">
            Attached document
          </a>
        </Typography>
        <Styled.DeleteButton
          data-testid="TimeOffModal-delete-attachment-button"
          role="button"
          onClick={handleTimeOffAttachmentDelete}
        >
          Remove current document
          <Icon icon={deleteIcon} className="mr-2" />
        </Styled.DeleteButton>
      </div>
    )
  }

  return (
    <UploadButton
      label="Edit supporting document"
      addText={doc.description}
      isLoading={doc.isLoading}
      isSuccess={doc.success}
      progress={doc.progress}
      onRemove={handleRemove()}
      onChange={(_file) => handleUpload(_file)}
      accept="application/pdf"
    />
  )
}

export default AttachmentFormItem
