import { useEffect, useState } from 'react'

export const useKompassifyNotifications = () => {
  const [notifCount, setNotifCount] = useState(0)

  useEffect(() => {
    const cb = ({ data }) => {
      if (
        data &&
        typeof data === 'object' &&
        data.TYPE === 'KOMPASSIFY_NOTIFS_UPDATE_UNREAD_NOTIFS'
      ) {
        setNotifCount(data.nbrUnreadNotifs)
      }
    }
    window.addEventListener('message', cb)
    return () => window.removeEventListener('message', cb)
  }, [])

  return notifCount
}
