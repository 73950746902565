import '../onboarding-checklist.styles.scss'

import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import React from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  .modal-dialog {
    justify-content: center;
  }
  .modal-content {
    padding: 0;
    width: 560px;
    color: #6d6d6c !important;

    .modal-header {
      margin-bottom: 0;
      padding: 24px 24px 16px;
    }
  }
`

export default ({ onClose, onSave }) => {
  return (
    <StyledModal show centered onHide={onClose}>
      <Modal.Header closeButton>
        <Typography className="heading_semibold__20 color_black">
          Ensuring Compliance
        </Typography>
      </Modal.Header>
      <Modal.Body>
        <Typography className="text_regular__14">
          Compliance is a key part of our partnership. In this step, you’ll
          complete a <b>Compliance Questionnaire </b>that includes
          <br />
          <br />
          <ul>
            <li>
              <b>Part 1:</b> Verification of your company&apos;s legal existence
              and the details of its Directors and UBOs (Ultimate Beneficial
              Owners). This step is managed by an external third-party provider
              SumSum who ensures the documents and information provided align
              with the local registry.
            </li>
            <li>
              <b>Part 2:</b> Corporate compliance, privacy, and information
              security checks to confirm your organization’s practices meet
              ethical and legal standards.
            </li>
          </ul>
          This process ensures risk reduction and legal integrity, strengthening
          our collaboration.
          <br />
          <br />
          <b>Time Estimate:</b> The company compliance step will require
          approximately <b>45 minutes</b> , including completion and review.
          Please ensure you have enough uninterrupted time to complete it.
        </Typography>
        <Typography className="mt-4 text_regular__14">
          Take the next step by filling out the Compliance Questionnaire to
          demonstrate your commitment to maintaining compliance. For detailed
          instructions, refer to{' '}
          <span className="mt-4 text_regular__14">
            <a
              href="https://remofirst.atlassian.net/wiki/external/NTU5MzVkMTM5NDhlNGNlNTg4OTVmMTRkNDI1NzE2YmQ"
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              our guides
            </a>
          </span>
        </Typography>
      </Modal.Body>

      <Modal.Footer>
        <div className="d-flex justify-content-end align-items-center">
          <Button
            priority="secondary"
            size="small"
            className="mr-2"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            size="small"
            priority="primary"
            onClick={onSave}
          >
            Complete
          </Button>
        </div>
      </Modal.Footer>
    </StyledModal>
  )
}
