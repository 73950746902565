import { EditForm } from '@atoms/EditModal/EditModalForm'
import Input from '@atoms/Input/Input'
import TextArea from '@atoms/TextArea/TextArea'
import Typography from '@atoms/Typography/Typography'
import { Attachment } from '@components/attachment/attachment.component'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components'

const WORD_LIMIT = 500

export const TimeOffBalancesAdHocForm = ({ onSubmit }) => {
  const {
    register,
    handleSubmit,
    control,
    resetField,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      note: '',
    },
  })

  const handleDelete = (fileId) => {
    resetField('documents')
  }

  const watchNote = watch('note')
  const isWordLimitExceeded = watchNote.length > WORD_LIMIT

  const WordCount = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-bottom: 1rem;
    font-variant-numeric: tabular-nums;
    color: ${isWordLimitExceeded ? '#f84f39' : ''};
  `

  return (
    <EditForm onSubmit={handleSubmit(onSubmit)}>
      <div className="remo-form-input">
        <Input
          {...register('days', {
            required: 'No. of days is required',
          })}
          type="number"
          label="No. of days"
          placeholder="Type a number"
          isRequired
        />
        {errors?.days && (
          <Typography className="text_regular__14 color_red">
            {errors.days.message}
          </Typography>
        )}
      </div>
      <div className="remo-form-input">
        <TextArea
          {...register('note', {
            required: 'Note is required',
            maxLength: {
              value: WORD_LIMIT,
              message: `Note cannot exceed ${WORD_LIMIT} characters`,
            },
          })}
          label="Note"
          placeholder="Provide more details of the adjustment"
          isRequired
        />

        {errors?.note && (
          <Typography className="text_regular__14 color_red">
            {errors.note.message}
          </Typography>
        )}
      </div>
      <WordCount>
        <Typography>
          Characters: {watchNote !== '' ? watchNote.length : 0} / {WORD_LIMIT}
        </Typography>
      </WordCount>
      <div className="remo-form-input">
        <Controller
          control={control}
          name="documents"
          rules={{
            required: 'Document is required',
          }}
          render={({ field }) => (
            <Attachment
              multiple={false}
              accept={{
                'image/*': ['.png', '.jpg', '.jpeg'],
                'application/pdf': ['.pdf'],
              }}
              onAttach={field.onChange}
              onDelete={(fileId) => handleDelete(fileId)}
              helperText="Supported file formats: pdf, jpeg, docx; file size up to 3 MB."
            />
          )}
        />
        {errors.documents && (
          <Typography className="text_regular__14 color_red">
            {errors.documents.message}
          </Typography>
        )}
      </div>
    </EditForm>
  )
}
