import { useApp } from '@core/context'
import { KeyboardArrowLeft } from '@mui/icons-material'
import {
  Dialog,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { Logo, StepContainer, StepIcon, theme } from '@remoteam-front/ui'
import { ClearFilledIcon } from '@remoteam-front/ui/icons'
import {
  getOnboardingChecklist,
  setCheckCountryGuide,
} from '@services/profile.service'
import { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'

import { NoticePeriod } from '../notice-period/notice-period.component'
import { ProbationPeriod } from '../probation-period/probation-period.component'
import { RequiredDocuments } from '../required-documents/required-documents.component'
import { WorkSchedule } from '../work-schedule/work-schedule.component'
import { Styled } from './country-guide-stepper.styles'

type Props = {
  onClose(): void
}

export const CountryGuideStepper = ({ onClose }: Props) => {
  const { profile } = useApp() as any

  const [activeStep, setActiveStep] = useState<number>(0)
  const matchesDownMobile = useMediaQuery(theme.breakpoints.down('mobile'))

  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1)
  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1)

  const queryClient = useQueryClient()

  const setCheckCountryGuideMutation = useMutation({
    mutationFn: () => setCheckCountryGuide(profile.id),
    onSuccess: () => {
      queryClient.refetchQueries(getOnboardingChecklist.key)
      onClose()
    },
  })

  const [steps, setSteps] = useState([
    {
      label: 'Probation period',
      content: (
        <ProbationPeriod
          countryId={profile?.allowed_countries[0]?.id}
          onNext={handleNext}
        />
      ),
    },
    {
      label: 'Notice period',
      content: (
        <NoticePeriod
          country={profile?.allowed_countries[0]}
          onNext={handleNext}
        />
      ),
    },
    {
      label: 'Working schedule',
      content: (
        <WorkSchedule
          countryId={profile?.allowed_countries[0]?.id}
          onNext={handleNext}
        />
      ),
    },
    {
      label: 'Required documents',
      content: (
        <RequiredDocuments
          isLoading={setCheckCountryGuideMutation.isLoading}
          onSave={setCheckCountryGuideMutation.mutate}
        />
      ),
    },
  ])

  // TODO remove after tests
  // const fetchStepsData = async (countryId: number) => {
  //   const [probationData, workScheduleData] = await Promise.all([
  //     getProbationPeriod.fetch(countryId),
  //     getWorkSchedule.fetch(countryId),
  //   ])
  //   return { probationData, workScheduleData }
  // }

  // const stepsDataQuery = useQuery(
  //   ['stepsData', profile?.allowed_countries[0]?.id],
  //   {
  //     enabled: !!profile?.allowed_countries.length,
  //     queryFn: () => fetchStepsData(profile?.allowed_countries[0]?.id),
  //     onSuccess: ({ probationData, workScheduleData }) => {
  //       setSteps((prev) => [
  //         {
  //           label: 'Probation period',
  //           content: (
  //             <ProbationPeriod data={probationData} onNext={handleNext} />
  //           ),
  //         },
  //         {
  //           label: 'Working schedule',
  //           content: (
  //             <WorkSchedule data={workScheduleData} onNext={handleNext} />
  //           ),
  //         },
  //         ...prev,
  //       ])
  //     },
  //   }
  // )

  // if (stepsDataQuery.isLoading) {
  //   return (
  //     <Box display="flex" justifyContent="center">
  //       <DotWave />
  //     </Box>
  //   )
  // }

  return (
    <Dialog open fullScreen onClose={onClose}>
      <Styled.Root>
        <Styled.Sidebar>
          <Styled.SidebarHeader>
            <Styled.LogoContainer>
              <Logo />
            </Styled.LogoContainer>
            <Typography variant="h2_strong" mb={matchesDownMobile ? 1.5 : 3}>
              Country Guides
            </Typography>
            <Typography variant="body1" color="product.secondaryVariant">
              Please provide details so we can begin collaborating together.
            </Typography>
          </Styled.SidebarHeader>
          <Styled.SidebarBody>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map(({ label }, index) => (
                <Step key={label}>
                  <StepLabel
                    optional={
                      <Typography variant="caption">
                        Step {index + 1}
                      </Typography>
                    }
                    StepIconComponent={StepIcon}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Styled.SidebarBody>
        </Styled.Sidebar>
        <Styled.Main>
          <Styled.MainHeader>
            <IconButton
              data-testid="country-guide-stepper.component-2A22D8"
              color="secondary"
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              <KeyboardArrowLeft />
            </IconButton>
            <Typography
              data-testid="country-guide-stepper.component-A06486"
              variant="h2_strong"
            >
              {steps[activeStep].label}
            </Typography>
            <IconButton
              data-testid="country-guide-stepper.component-EC7470"
              color="secondary"
              onClick={onClose}
            >
              <ClearFilledIcon />
            </IconButton>
          </Styled.MainHeader>
          <Styled.MainBody>
            {steps.map(({ label, content }, index) => (
              <StepContainer key={label} visible={activeStep === index}>
                {content}
              </StepContainer>
            ))}
          </Styled.MainBody>
        </Styled.Main>
      </Styled.Root>
    </Dialog>
  )
}
