import moment from 'moment'

export const monthOptions = (startDate) => {
  const date = new Date(moment(new Date(startDate)).format('YYYY-MM-DD'))
  const months = moment.months()
  date.setDate(1)
  const filterMonth = []
  months.forEach(() => {
    filterMonth.push({
      label: `${months[date.getMonth()]} ${date.getFullYear()}`,
      value: moment(date).format('YYYY-MM-DD'),
      month: date.getMonth(),
    })
    date.setMonth(date.getMonth() + 1)
  })
  return filterMonth
}

export const paymentTypes = [
  { value: 'BONUS', label: 'Bonus' },
  { value: 'COMMISSION', label: 'Commission' },
  { value: 'RETRO', label: 'Retro' },
  { value: 'OVERTIME', label: 'Overtime' },
  { value: 'ALLOWANCE', label: 'Allowance' },
  { value: 'DEDUCTION', label: 'Deduction' },
  { value: 'WORK_EQUIPMENT', label: 'Work Equipment' },
  { value: 'OTHER', label: 'Other' },
]

export const frequencyTypes = [
  { value: 'ONE_TIME', label: 'One-time' },
  { value: 'MONTHLY', label: 'Monthly' },
  { value: 'EVERY_2_MONTHS', label: 'Every 2 months' },
  { value: 'EVERY_3_MONTHS', label: 'Every 3 months' },
]

export const getFrequencyMessage = (frequency) => {
  switch (frequency?.value) {
    case 'ONE_TIME':
      return 'This payment will be made only once in the next available payroll.'
    case 'MONTHLY':
      return 'This payment will reoccur every month.'
    case 'EVERY_2_MONTHS':
      return 'This payment will reoccur every 2 months.'
    case 'EVERY_3_MONTHS':
      return 'This payment will reoccur every 3 months.'
    default:
      return ''
  }
}

export const calculateLastPayoutMonth = (
  firstMonth,
  frequency,
  occurrences
) => {
  if (!firstMonth?.value || !frequency?.value || !occurrences) return null

  const monthMultiplier = {
    MONTHLY: 1,
    EVERY_2_MONTHS: 2,
    EVERY_3_MONTHS: 3,
  }

  const firstPayoutDate = moment(firstMonth.value).clone()
  const totalMonthsToAdd = monthMultiplier[frequency.value] * (occurrences - 1)
  const lastPayoutDate = firstPayoutDate.add(totalMonthsToAdd, 'months')

  return `${lastPayoutDate.format('MMMM YYYY')}`
}

export const getOccurrencesDisplay = (payment) => {
  if (!payment?.is_recurrent) {
    return '1 of 1 occurrences'
  }

  if (!payment?.recurrence_ending_month) {
    return 'Indefinite'
  }
  const totalOccurrences =
    payment?.occurrences ||
    (() => {
      const startDate = moment(payment.recurrence_starting_month)
      const endDate = moment(payment.recurrence_ending_month)

      const monthMultiplier = {
        MONTHLY: 1,
        EVERY_2_MONTHS: 2,
        EVERY_3_MONTHS: 3,
      }

      const monthDiff = endDate.diff(startDate, 'months') + 1
      return Math.ceil(
        monthDiff / (monthMultiplier[payment.recurrence_frequency] || 1)
      )
    })()

  return `${payment.number_of_occurrences} of ${totalOccurrences} occurrences`
}
