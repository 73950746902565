import Button from '@atoms/Button/Button'
import List from '@atoms/List/List'
import Typography from '@atoms/Typography/Typography'
import { InfoMessage } from '@components/info-message/info-message.component'
import { Loader } from '@components/loader/loader.component'
import { useToast } from '@core/hooks/useErrorNotification'
import { PaidBereavementModal } from '@pages/employeeDetailPage/libs/TimeOff/PaidBereavementModal'
import { PaidParentalModal } from '@pages/employeeDetailPage/libs/TimeOff/PaidParentalModal'
import { PaidSickLeaveModal } from '@pages/employeeDetailPage/libs/TimeOff/PaidSickLeaveModal'
import { PaidTimeOffModal } from '@pages/employeeDetailPage/libs/TimeOff/PaidTimeOffModal'
import {
  getPaidBereavementLeaves,
  getPaidParental,
  getPaidSickLeaves,
  getPaidTimeOff,
} from '@pages/employeeDetailPage/mock'
import {
  getContractsPaidLeaves,
  postContractsPaidLeaves,
} from '@services/contract.service'
import { fetchCountryPaidLeavesById } from '@services/countries.service'
import React from 'react'
import { useMutation, useQuery } from 'react-query'
import styled from 'styled-components'
import { useBoolean } from 'usehooks-ts'

import TimeOffCarryOverForm from './TimeOffCarryOverForm'

const Container = styled.div`
  border: 2px solid #f3f3f3;
  border-radius: 8px;
  height: 100%;
  padding: 16px;
  padding-bottom: 0;
`

export default function TimeOffEmployee({ employee, refetchEmployee }) {
  const { successAlert } = useToast()
  const { value: valuePaidTimeOff, toggle: togglePaidTimeOff } =
    useBoolean(false)
  const { value: valuePaidSick, toggle: togglePaidSick } = useBoolean(false)
  const { value: valuePaidParental, toggle: togglePaidParental } =
    useBoolean(false)
  const { value: valueBereavement, toggle: toggleBereavement } =
    useBoolean(false)

  const { data: countryTimeoffPolicyData, isLoading } = useQuery(
    [fetchCountryPaidLeavesById.key, employee.working_country.id],
    {
      queryFn: () => fetchCountryPaidLeavesById(employee.working_country.id),
    }
  )

  const isCarryOverAllowedByCountry = countryTimeoffPolicyData?.allow_carryover

  const { data, refetch } = useQuery(['getContractsPaidLeaves', employee.id], {
    queryFn: () => getContractsPaidLeaves(employee.id),
  })

  const updateContractsPaidLeaves = useMutation({
    mutationFn: (payload) => postContractsPaidLeaves(payload.id, payload.data),
    onSuccess: () => {
      refetch()
      successAlert('Successfully updated!')
      if (valuePaidSick) togglePaidSick()
      if (valueBereavement) toggleBereavement()
      if (valuePaidParental) togglePaidParental()
      if (valuePaidTimeOff) togglePaidTimeOff()
    },
  })
  const onSubmit = (payload) => {
    updateContractsPaidLeaves.mutate({ id: employee.id, data: payload })
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <Typography className="heading_semibold__20 color_black">
          Time-off
        </Typography>
      </div>
      {data && (
        <div className="row justify-content-between">
          {data.leave_days.paid_leave && (
            <div className="col-6 mb-3">
              <Container>
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <Typography className="heading_semibold__20 color_black">
                    Paid time - off days
                  </Typography>
                  <Button
                    data-testid="TimeOffEmployee-73BF54"
                    priority="secondary"
                    size="small"
                    onClick={togglePaidTimeOff}
                  >
                    Edit
                  </Button>
                </div>
                <List
                  hideLastBorder
                  textPosition="horizontal"
                  lists={getPaidTimeOff(data.leave_days.paid_leave).map(
                    (v) => ({
                      ...v,
                      value: v.value?.join(', '),
                    })
                  )}
                />
              </Container>
            </div>
          )}
          {data.leave_days.paid_sick_leave && (
            <div className="col-6 mb-3">
              <Container>
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <Typography className="heading_semibold__20 color_black">
                    Paid sick leaves
                  </Typography>
                  <Button
                    data-testid="TimeOffEmployee-E5D95F"
                    priority="secondary"
                    size="small"
                    onClick={togglePaidSick}
                  >
                    Edit
                  </Button>
                </div>
                <List
                  hideLastBorder
                  textPosition="horizontal"
                  lists={getPaidSickLeaves(data.leave_days.paid_sick_leave).map(
                    (v) => ({
                      ...v,
                      value: v.value?.join(', '),
                    })
                  )}
                />
              </Container>
            </div>
          )}
          {data.leave_days.paid_bereavement_leave && (
            <div className="col-6 mb-3">
              <Container>
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <Typography className="heading_semibold__20 color_black">
                    Paid bereavement leaves
                  </Typography>
                  <Button
                    data-testid="TimeOffEmployee-071A30"
                    priority="secondary"
                    size="small"
                    onClick={toggleBereavement}
                  >
                    Edit
                  </Button>
                </div>
                <List
                  hideLastBorder
                  textPosition="horizontal"
                  lists={getPaidBereavementLeaves(
                    data.leave_days.paid_bereavement_leave
                  ).map((v) => ({
                    ...v,
                    value: v.value?.join(', '),
                  }))}
                />
              </Container>
            </div>
          )}
          {data.leave_days.paid_parental_leave && (
            <div className="col-6 mb-3">
              <Container>
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <Typography className="heading_semibold__20 color_black">
                    Paid parental leaves
                  </Typography>
                  <Button
                    data-testid="TimeOffEmployee-650010"
                    priority="secondary"
                    size="small"
                    onClick={togglePaidParental}
                  >
                    Edit
                  </Button>
                </div>
                <List
                  textPosition="horizontal"
                  hideLastBorder
                  lists={getPaidParental(
                    data.leave_days.paid_parental_leave
                  ).map((v) => ({
                    ...v,
                    value: v.value?.join(', '),
                  }))}
                />
              </Container>
            </div>
          )}
        </div>
      )}
      <div className="col-6">
        <Container>
          <div className="d-flex flex-column">
            {isCarryOverAllowedByCountry ? (
              <InfoMessage
                type="success"
                message="You can configure the carryover settings below. By default, these settings inherit your company’s time-off policy, but you can override them for individual employees."
                fullWidth
              />
            ) : (
              <InfoMessage
                message={`Carryover is not supported in ${employee?.working_country?.name}`}
                fullWidth
              />
            )}
            <TimeOffCarryOverForm
              employee={employee}
              refetchEmployee={refetchEmployee}
              isCarryOverAllowedByCountry={isCarryOverAllowedByCountry}
            />
          </div>
        </Container>
      </div>
      {valuePaidParental && data && (
        <PaidParentalModal
          title="Paid parental leaves"
          paidParentalLeave={data.leave_days.paid_parental_leave}
          loading={updateContractsPaidLeaves.isLoading}
          onClose={togglePaidParental}
          onSubmit={onSubmit}
        />
      )}
      {valueBereavement && data && (
        <PaidBereavementModal
          title="Paid bereavement leaves"
          paidBereavementLeave={data.leave_days.paid_bereavement_leave}
          loading={updateContractsPaidLeaves.isLoading}
          onClose={toggleBereavement}
          onSubmit={onSubmit}
        />
      )}
      {valuePaidTimeOff && data && (
        <PaidTimeOffModal
          title="Paid time - off days"
          onSubmit={onSubmit}
          loading={updateContractsPaidLeaves.isLoading}
          onClose={togglePaidTimeOff}
          paidLeave={data.leave_days.paid_leave}
        />
      )}
      {valuePaidSick && data && (
        <PaidSickLeaveModal
          title="Paid sick leaves"
          paidSickLeave={data.leave_days.paid_sick_leave}
          loading={updateContractsPaidLeaves.isLoading}
          onClose={togglePaidSick}
          onSubmit={onSubmit}
        />
      )}
    </div>
  )
}
