import './EmployeesExpense.scss'

import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import Input from '@atoms/Input/Input'
import NotFound from '@atoms/NotFound/NotFound'
import NotificationBlock from '@atoms/NotificationBlock/NotificationBlock'
import PageTitle from '@atoms/PageTitle/PageTitle'
import Pagination from '@atoms/Pagination/Pagination'
import Select from '@atoms/Select/Select'
import Tab from '@atoms/Tabs/libs/Tab/Tab'
import Tabs from '@atoms/Tabs/Tabs'
import Typography from '@atoms/Typography/Typography'
import { EXPENSE_STATUSES } from '@core/constants'
import { useToast } from '@core/hooks/useErrorNotification'
import usePagination from '@core/hooks/usePagination'
import { useRouteQuery, useSearchParams } from '@core/hooks/useRouteQuery'
import { coin, searchIcon } from '@core/icons/icons'
import * as icons from '@core/icons/icons'
import { capitalizeFirstLetter, mapEnum } from '@core/utils'
import { getCompanies } from '@services/company.service'
import { createExpense, getExpenseManagements } from '@services/expense.service'
import { DotWave } from '@uiball/loaders'
import { last } from 'lodash'
import React, { useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { HiDownload } from 'react-icons/hi'
import { useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { useBoolean, useDebounce } from 'usehooks-ts'

import { CreateExpenseModal } from './create-expense-modal/create-expense-modal.component'
import DownloadExpenseReceipts from './DownloadExpenseReceipts'
import DownloadReport from './DownloadReport'
import ExpenseAccordion from './ExpenseAccordion'

export default function EmployeesExpense() {
  const { params, setParams } = useSearchParams()
  const [search, setSearch] = useState(params.get('search') || '')
  const [company, setCompany] = useState(params.get('company') || '')
  const [statusFilterValue, setStatusFilterValue] = useState('')
  const [isPending, setIsPending] = useState(true)
  const [defaultStatuses, setDefaultStatuses] = useState(
    'PENDING,AWAITING_PAYMENT'
  )
  const [FilterStates, setFilterStates] = useState([
    EXPENSE_STATUSES.PENDING,
    EXPENSE_STATUSES.AWAITING_PAYMENT,
  ])
  const debouncedSearch = useDebounce(search, 500)
  const history = useHistory()
  const { successAlert } = useToast()
  const routeQuery = useRouteQuery()
  const { page, limit, setPage } = usePagination({
    page: 1,
    limit: 10,
  })

  const [createdExpenseId, setCreatedExpenseId] = useState(null)
  const [tab, setTab] = useState(0)
  const isOpenDownloadCsv = useBoolean(false)
  const isOpenDownloadExpenseReceipts = useBoolean(false)

  const { data: companiesData, isLoading: companiesLoading } = useQuery(
    ['getCompanies'],
    () => getCompanies()
  )

  const { data, isLoading, isFetching, refetch } = useQuery(
    [
      'employeeExpense',
      debouncedSearch,
      statusFilterValue,
      isPending,
      company,
      page,
      limit,
    ],
    {
      queryFn: () => {
        const params = {
          offset: (page - 1) * limit,
          limit,
          search: debouncedSearch,
          company_in: company,
        }

        if (statusFilterValue) {
          params.state_in = statusFilterValue
        } else {
          params.state_in = defaultStatuses
        }

        if (isPending) {
          params.is_pending = true
        }

        return getExpenseManagements(params)
      },
    }
  )

  const onTabChange = (id) => {
    setTab(id)
    setPage(1)
    setStatusFilterValue('')
    if (id === 0) {
      setIsPending(true)
      setDefaultStatuses('PENDING,AWAITING_PAYMENT')
      setFilterStates([
        EXPENSE_STATUSES.PENDING,
        EXPENSE_STATUSES.AWAITING_PAYMENT,
      ])
    } else if (id === 1) {
      setIsPending(false)
      setDefaultStatuses('CANCELLED,PAID,REJECTED')
      setFilterStates([
        EXPENSE_STATUSES.CANCELLED,
        EXPENSE_STATUSES.PAID,
        EXPENSE_STATUSES.REJECTED,
      ])
    }
  }

  const onChangeSearch = (evt) => {
    const val = evt.target.value
    setSearch(val)
    setParams({ search: val, status: statusFilterValue, company })
    setPage(1)
  }

  const onSelectStatus = (selectedOption) => {
    const selectedStatus = selectedOption?.value || ''
    setStatusFilterValue(selectedStatus)
    setParams({ status: selectedStatus, search, company })
    setPage(1)
  }

  const onSelectCompany = (selectedOption) => {
    const selectedCompany = selectedOption?.value || null
    setCompany(selectedCompany)
    setParams({ company: selectedCompany, search, status: statusFilterValue })
    setPage(1)
  }

  const handleClearAll = () => {
    setSearch('')
    setStatusFilterValue('')
    setCompany(null)
    setParams({ search: '', status: '', company: '' })
    setPage(1)
  }

  const newExpenseModalState = useBoolean(
    Boolean(routeQuery.get('openAddModal'))
  )

  const handleModalOpen = () => {
    newExpenseModalState.setTrue()
  }

  const handleModalClose = () => {
    routeQuery.delete('openAddModal')
    history.replace({
      search: routeQuery.toString(),
    })
    newExpenseModalState.setFalse()
  }

  const createExpenseRequest = useMutation({
    mutationFn: (payload) => createExpense(payload),
    onSuccess: (response) => {
      if (response.data) {
        setCreatedExpenseId(last(response.data.id))
      }
      handleModalClose()
      refetch()
      successAlert('Successfully created!')
    },
  })

  const renderTable = React.useMemo(() => {
    return data?.results.length > 0 ? (
      <div className="d-flex flex-column mb-4">
        <div className="d-flex px-3">
          <div className="row expense-table-header w-100">
            <div className="col-2">
              <Typography className="text_regular-normal__14 color_text_300">
                Team member
              </Typography>
            </div>
            <div className="col-2">
              <Typography className="text_regular-normal__14 color_text_300">
                Name
              </Typography>
            </div>
            <div className="col-2">
              <Typography className="text_regular-normal__14 color_text_300">
                Submitted
              </Typography>
            </div>
            <div className="col-1 d-flex align-items-center">
              <Typography className="text_regular-normal__14 color_text_300">
                Amount
              </Typography>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    Expenses will be converted when the expense has been
                    approved.
                  </Tooltip>
                }
              >
                <span style={{ marginLeft: 4, verticalAlign: 'top' }}>
                  <Icon icon={icons.infoIcon} style={{ fill: '#878787' }} />
                </span>
              </OverlayTrigger>
            </div>
            <div className="col-2 d-flex">
              <Typography className="text_regular-normal__14 color_text_300">
                Created by
              </Typography>
            </div>
            <div className="col-1 d-flex">
              <Typography className="text_regular-normal__14 color_text_300">
                Expected payout
              </Typography>
            </div>
            <div className="col-2 justify-content-end d-flex">
              <Typography className="text_regular-normal__14 color_text_300">
                Status
              </Typography>
            </div>
          </div>
        </div>
        <div className="">
          <ExpenseAccordion
            createdExpenseId={createdExpenseId}
            expenses={data?.results}
            refetch={refetch}
          />
        </div>
        {data.count && (
          <div className="row align-items-center justify-content-end mt-3">
            <div className="col-auto">
              <Pagination
                total={data.count}
                pageSize={limit}
                page={page}
                onGetPage={setPage}
              />
            </div>
          </div>
        )}
      </div>
    ) : (
      <div>
        <NotFound
          title="It's quiet in here ..."
          action="+ Submit expense"
          onClickAction={handleModalOpen}
        />
      </div>
    )
  }, [data])

  return (
    <div className="employees-time-page">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <PageTitle> Expenses </PageTitle>
        <div className="d-flex gap-2">
          <div>
            <Button
              data-testid="EmployeesExpense-516690"
              priority="secondary"
              size="small"
              onClick={isOpenDownloadExpenseReceipts.setTrue}
            >
              <HiDownload className="mr-1" />
              Download expense receipts
            </Button>
          </div>
          <div>
            <Button
              data-testid="EmployeesExpense-516689"
              priority="secondary"
              size="small"
              onClick={isOpenDownloadCsv.setTrue}
            >
              <HiDownload className="mr-1" />
              Download report
            </Button>
          </div>
          <div>
            <Button
              data-testid="EmployeesExpense-8B0D76"
              className="ml-2"
              priority="secondary"
              size="small"
              onClick={handleModalOpen}
            >
              Submit expense
            </Button>
          </div>
        </div>
      </div>
      <NotificationBlock
        render={
          <Typography className="text_regular__14 ml-2">
            Manage expense requests from your team.
          </Typography>
        }
        cards={[
          {
            title: 'Submit an expense request',
            description: `Select the “Submit expense” button and create an expense request. The client will then need to approve this before their cut-off date. If it is approved after their cut-off date, it will be automatically added to the next month’s payroll.`,
            icon: coin,
          },
          {
            title: 'Review expenses requests',
            description:
              'View all expense requests created by clients or employees',
            icon: coin,
          },
        ]}
      />
      <div className="d-flex my-4 align-items-center gap-3">
        <div style={{ width: 250 }}>
          <Input
            placeholder="Employee name"
            type="text"
            onChange={onChangeSearch}
            value={search}
            name="search"
            endIcon={<Icon icon={searchIcon} />}
            styleClass="employees-page-search"
            style={{ fontSize: '14px' }}
          />
        </div>
        <div style={{ width: 190 }}>
          <Select
            placeholder="Select status"
            value={statusFilterValue || undefined}
            onChange={onSelectStatus}
            isClearable
            options={FilterStates.map((state) => ({
              value: state,
              label: mapEnum(capitalizeFirstLetter(state)),
            }))}
          />
        </div>
        <div style={{ width: 190 }}>
          <Select
            isClearable
            showSearch
            placeholder="Company"
            value={company}
            onChange={onSelectCompany}
            options={
              companiesData?.results?.map((comp) => ({
                value: comp.id,
                label: comp.name,
              })) || []
            }
            isDisabled={companiesLoading}
          />
        </div>
        <div>
          <Button onClick={handleClearAll} priority="secondary" size="small">
            Clear all
          </Button>
        </div>
      </div>
      <Tabs className="mt-4" onTabChange={onTabChange} selectedTab={tab}>
        <Tab tabId={0} title="Pending">
          {isLoading || isFetching ? (
            <div className="d-flex w-100 h-100 align-items-center justify-content-center">
              <DotWave size={48} speed={1} color="black" />
            </div>
          ) : (
            <div className="expense-table-box">
              <div className="expense-table-wrap">{renderTable}</div>
            </div>
          )}
        </Tab>
        <Tab tabId={1} title="History">
          {isLoading || isFetching ? (
            <div className="d-flex w-100 h-100 align-items-center justify-content-center">
              <DotWave size={48} speed={1} color="black" />
            </div>
          ) : (
            <div className="expense-table-box">
              <div className="expense-table-wrap">{renderTable}</div>
            </div>
          )}
        </Tab>
      </Tabs>

      {newExpenseModalState.value && (
        <CreateExpenseModal
          onClose={handleModalClose}
          loading={createExpenseRequest.isLoading}
          onSubmit={(formData) => createExpenseRequest.mutate(formData)}
        />
      )}

      {isOpenDownloadCsv.value && (
        <DownloadReport onClose={isOpenDownloadCsv.setFalse} />
      )}
      {isOpenDownloadExpenseReceipts.value && (
        <DownloadExpenseReceipts
          onClose={isOpenDownloadExpenseReceipts.setFalse}
        />
      )}
    </div>
  )
}
