import Button from '@atoms/Button/Button'
import Input from '@atoms/Input/Input'
import RadioButton from '@atoms/RadioButton/RadioButton'
import Select from '@atoms/Select/Select'
import Typography from '@atoms/Typography/Typography'
import {
  TimeOffCarryoverExpiry,
  TimeOffCarryoverExpiryUnit,
  TimeOffCarryoverRule,
} from '@core/constants'
import { useToast } from '@core/hooks/useErrorNotification'
import { Styled } from '@features/time-off/time-off-policy-form/time-off-policy-form.styles'
import { updateEmployeeTimeoffPolicy } from '@services/contract.service'
import { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useBoolean } from 'usehooks-ts'

const maxCarryOverMap = {
  days: 365,
  weeks: 52,
  months: 12,
  years: 1,
}

export default function TimeOffCarryOverForm({
  employee,
  refetchEmployee,
  isCarryOverAllowedByCountry,
}) {
  const carryoverSettings = employee?.paid_leave

  const { successAlert, failedAlert } = useToast()

  const shouldShowConfirmationModal = useBoolean(false)
  const shouldShowInfoModal = useBoolean(false)

  const editEmployeeCarryoverMutation = useMutation({
    mutationFn: (payload) => updateEmployeeTimeoffPolicy(employee.id, payload),
    onSuccess: () => {
      shouldShowInfoModal.setTrue()
    },
    onError: () => {
      failedAlert('Please try again')
    },
  })

  const {
    control,
    formState: { isDirty, errors },
    handleSubmit,
    watch,
    register,
    resetField,
    setValue,
  } = useForm({
    defaultValues: {
      allowCarryover: isCarryOverAllowedByCountry
        ? String(carryoverSettings?.allow_carryover)
        : 'false',
      carryoverRule: carryoverSettings.carryover_rule,
      carryoverExpiry:
        carryoverSettings.carryover_expiry_amount === 0 ? 'none' : 'custom',
      carryoverExpiryUnit: carryoverSettings.carryover_expiry_unit,
      carryoverExpiryAmount: String(carryoverSettings.carryover_expiry_amount),
      maxCarryoverDays: String(
        carryoverSettings.maximum_carryover_days_per_year
      ),
    },
  })

  const watchAllowCarryover = watch('allowCarryover')
  const watchCarryoverRule = watch('carryoverRule')
  const watchMaxCarryoverDays = watch('maxCarryoverDays')
  const watchCarryoverExpiry = watch('carryoverExpiry')
  const watchCarryoverExpiryUnit = watch('carryoverExpiryUnit')
  const watchCarryoverExpiryAmount = watch('carryoverExpiryAmount')

  useEffect(() => {
    if (watchCarryoverExpiry === 'none') {
      setValue('carryoverExpiryAmount', 0)
      setValue('carryoverExpiryUnit', TimeOffCarryoverExpiryUnit.Days)
    }

    if (watchCarryoverRule === 'unlimited') {
      setValue('maxCarryoverDays', 0)
    }
  }, [resetField, setValue, watchCarryoverExpiry, watchCarryoverRule])

  const onSubmit = () => {
    shouldShowConfirmationModal.setTrue()
  }

  const confirmSubmit = (formData) => {
    shouldShowConfirmationModal.setFalse()

    const payload = {
      ...carryoverSettings,
      allow_carryover: formData.allowCarryover === 'true',
      carryover_rule: formData.carryoverRule,
      maximum_carryover_days_per_year: +formData.maxCarryoverDays,
      carryover_expiry_amount: +formData.carryoverExpiryAmount,
      carryover_expiry_unit: formData.carryoverExpiryUnit,
    }

    if (formData.carryoverRule === 'unlimited') {
      payload.maximum_carryover_days_per_year = 0
    }

    if (formData.carryoverExpiry === 'none') {
      payload.carryover_expiry_amount = 0
      payload.carryover_expiry_unit = 'days'
    }

    editEmployeeCarryoverMutation.mutate(payload)
  }

  const handleInfoModalAction = () => {
    shouldShowInfoModal.setFalse()

    if (editEmployeeCarryoverMutation.isSuccess) {
      successAlert('Carryover setting successfully updated')
    }

    if (editEmployeeCarryoverMutation.isError) {
      failedAlert('Something went wrong, try again')
    }

    refetchEmployee()
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="remo-form-input flex-column">
          <Controller
            control={control}
            name="allowCarryover"
            render={({ field }) => (
              <RadioButton
                {...field}
                label="Allow carryover"
                layout="vertical"
                column
                disabled={!isCarryOverAllowedByCountry}
                options={[
                  {
                    id: 'true',
                    text: 'Yes',
                    value: 'true',
                  },
                  {
                    id: 'false',
                    text: 'No',
                    value: 'false',
                  },
                ]}
              />
            )}
          />
        </div>
        {watchAllowCarryover === 'true' && (
          <div className="remo-form-input flex-column">
            <Controller
              control={control}
              name="carryoverRule"
              render={({ field }) => (
                <RadioButton
                  {...field}
                  label="Carryover options"
                  layout="vertical"
                  column
                  options={[
                    {
                      id: 'unlimited',
                      text: 'Unlimited',
                      value: TimeOffCarryoverRule.Unlimited,
                    },
                    {
                      id: 'limited',
                      text: 'Limited',
                      value: TimeOffCarryoverRule.Limited,
                    },
                  ]}
                />
              )}
            />
          </div>
        )}
        {watchAllowCarryover === 'true' &&
          watchCarryoverRule === TimeOffCarryoverRule.Limited && (
            <>
              <Styled.CarryoverMaximum>
                <Styled.Label>
                  Please specify maximum carryover days per year:
                </Styled.Label>
                <Styled.SmallInput>
                  <Input
                    {...register('maxCarryoverDays', {
                      required: 'Set maximum carryover days',
                      min: {
                        value: 0,
                        message: 'Carry over days cannot be negative',
                      },
                      max: {
                        value: 365,
                        message: 'Carry over days cannot exceed 365',
                      },
                    })}
                    type="number"
                  />
                </Styled.SmallInput>
              </Styled.CarryoverMaximum>
              {errors.maxCarryoverDays && (
                <Typography className="text_regular__14 color_red">
                  {errors.maxCarryoverDays.message}
                </Typography>
              )}
            </>
          )}

        {watchAllowCarryover === 'true' && (
          <div className="remo-form-input flex-column">
            <Controller
              control={control}
              name="carryoverExpiry"
              rules={{ required: 'Choose one option' }}
              render={({ field }) => (
                <RadioButton
                  {...field}
                  label="Carryover expiry"
                  layout="vertical"
                  column
                  options={[
                    {
                      id: 'none',
                      text: 'None: carried over days will not expire, and can be used at any point in the future',
                      value: TimeOffCarryoverExpiry.None,
                    },
                    {
                      id: 'custom',
                      text: 'Set expiry',
                      value: TimeOffCarryoverExpiry.Custom,
                    },
                  ]}
                />
              )}
            />
            {errors.carryoverExpiry && (
              <Typography className="text_regular__14 color_red">
                {errors.carryoverExpiry.message}
              </Typography>
            )}

            {watchCarryoverExpiry === TimeOffCarryoverExpiry.Custom && (
              <>
                <Styled.CarryoverMaximum>
                  <Styled.Label>
                    Please specify maximum carryover per year:
                  </Styled.Label>
                  <Styled.SmallInput>
                    <Input
                      {...register('carryoverExpiryAmount', {
                        required: 'Set expiry amount',
                        min: {
                          value: 0,
                          message: 'Carry over expiry cannot be negative',
                        },
                        max: {
                          value: maxCarryOverMap[watchCarryoverExpiryUnit],
                          message: `Carry over expiry cannot exceed ${maxCarryOverMap[watchCarryoverExpiryUnit]} ${watchCarryoverExpiryUnit}`,
                        },
                      })}
                      type="number"
                    />
                  </Styled.SmallInput>
                  <Controller
                    control={control}
                    name="carryoverExpiryUnit"
                    rules={{
                      required: 'Select expiry unit',
                    }}
                    render={({ field }) => {
                      return (
                        <Styled.SmallInput>
                          <Select
                            {...field}
                            options={[
                              {
                                value: TimeOffCarryoverExpiryUnit.Days,
                                label: 'days',
                              },
                              {
                                value: TimeOffCarryoverExpiryUnit.Weeks,
                                label: 'weeks',
                              },
                              {
                                value: TimeOffCarryoverExpiryUnit.Months,
                                label: 'months',
                              },
                              {
                                value: TimeOffCarryoverExpiryUnit.Years,
                                label: 'years',
                              },
                            ]}
                            onChange={({ value }) => field.onChange(value)}
                          />
                        </Styled.SmallInput>
                      )
                    }}
                  />
                </Styled.CarryoverMaximum>
                {errors.carryoverExpiryAmount && (
                  <Typography className="text_regular__14 color_red">
                    {errors.carryoverExpiryAmount.message}
                  </Typography>
                )}
                {errors?.carryoverExpiryUnit && (
                  <Typography className="text_regular__14 color_red">
                    {errors.carryoverExpiryUnit.message}
                  </Typography>
                )}
              </>
            )}
          </div>
        )}
        {watchAllowCarryover && (
          <div>
            <Button
              size="small"
              className="mr-2 mb-4"
              type="submit"
              disabled={!isDirty}
            >
              Save changes
            </Button>
          </div>
        )}
      </form>

      <Modal
        show={shouldShowConfirmationModal.value}
        onHide={shouldShowConfirmationModal.setFalse}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Change carryover policy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Typography>
            You are about to change the carryover policy for{' '}
            {employee.profile.first_name} {employee.profile.last_name}:
          </Typography>
          <br />
          {String(carryoverSettings.allow_carryover) !==
            watchAllowCarryover && (
            <Typography>
              - <span className="text_medium__16">Allow carryover:</span> from{' '}
              <span className="text_regular__16 color_red">
                {String(carryoverSettings.allow_carryover)}
              </span>{' '}
              to{' '}
              <span className="text_regular__16 color_primary">
                {watchAllowCarryover}
              </span>
            </Typography>
          )}
          {watchAllowCarryover === 'true' &&
            carryoverSettings.carryover_rule !== watchCarryoverRule && (
              <Typography>
                -{' '}
                <span className="text_medium__16">Maximum carryover days:</span>{' '}
                from{' '}
                <span className="text_regular__16 color_red">
                  {carryoverSettings.carryover_rule}
                </span>{' '}
                to{' '}
                <span className="text_regular__16 color_primary">
                  {watchCarryoverRule}
                </span>
              </Typography>
            )}
          {watchAllowCarryover === 'true' &&
            String(carryoverSettings.maximum_carryover_days_per_year) !==
              watchMaxCarryoverDays && (
              <Typography>
                -{' '}
                <span className="text_medium__16">Maximum carryover days:</span>{' '}
                from{' '}
                <span className="text_regular__16 color_red">
                  {carryoverSettings.maximum_carryover_days_per_year}
                </span>{' '}
                to{' '}
                <span className="text_regular__16 color_primary">
                  {watchMaxCarryoverDays}
                </span>
              </Typography>
            )}
          {watchAllowCarryover === 'true' &&
            carryoverSettings.carryover_expiry_amount !== 0 &&
            watchCarryoverExpiry === 'none' && (
              <Typography>
                -{' '}
                <span className="text_medium__16">
                  Carryover expiry option:
                </span>{' '}
                from <span className="text_regular__16 color_red">custom</span>{' '}
                to <span className="text_regular__16 color_primary">none</span>
              </Typography>
            )}
          {watchAllowCarryover === 'true' &&
            carryoverSettings.carryover_expiry_amount === 0 &&
            watchCarryoverExpiry === 'custom' && (
              <Typography>
                -{' '}
                <span className="text_medium__16">
                  Carryover expiry option:
                </span>{' '}
                from <span className="text_regular__16 color_red">none</span> to{' '}
                <span className="text_regular__16 color_primary">custom</span>
              </Typography>
            )}
          {watchAllowCarryover === 'true' &&
            (String(carryoverSettings.carryover_expiry_amount) !==
              watchCarryoverExpiryAmount ||
              carryoverSettings.carryover_expiry_unit !==
                watchCarryoverExpiryUnit) && (
              <Typography>
                - <span className="text_medium__16">Carryover expiry:</span>{' '}
                from{' '}
                <span className="text_regular__16 color_red">
                  {carryoverSettings.carryover_expiry_amount}{' '}
                  {carryoverSettings.carryover_expiry_unit}
                </span>{' '}
                to{' '}
                <span className="text_regular__16 color_primary">
                  {watchCarryoverExpiryAmount} {watchCarryoverExpiryUnit}
                </span>
              </Typography>
            )}
          <br />
          Please confirm to proceed.
        </Modal.Body>
        <Modal.Footer>
          <Button
            priority="secondary"
            size="small"
            onClick={shouldShowConfirmationModal.setFalse}
          >
            Cancel
          </Button>
          <Button size="small" onClick={handleSubmit(confirmSubmit)}>
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={shouldShowInfoModal.value}
        onHide={handleInfoModalAction}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Typography>Paid time off policy change requested</Typography>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Typography>
            Our team has received your change request and will verify if it
            complies with local regulation and if it has impact on your
            employment agreements.
          </Typography>
          <br />
          <Typography>We will get in touch if needed.</Typography>
        </Modal.Body>
        <Modal.Footer>
          <Button size="small" onClick={handleInfoModalAction}>
            Understood
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
