import { WorkScheduleTable } from '@components/work-schedule-table/work-schedule-table.component'
import { Alert, Box, Button } from '@mui/material'
import { getWorkSchedule } from '@remoteam-front/services'
import { DotWave } from '@uiball/loaders'
import { useQuery } from 'react-query'

type Props = {
  countryId: number
  onNext(): void
}

export const WorkSchedule = ({ countryId, onNext }: Props) => {
  const workScheduleQuery = useQuery([getWorkSchedule.key], {
    enabled: !!countryId,
    queryFn: () => getWorkSchedule.fetch(countryId),
  })

  if (workScheduleQuery.isLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <DotWave />
      </Box>
    )
  }

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      {workScheduleQuery.data ? (
        <>
          <Alert severity="info">
            If you spot any mistake in the tables below, please reach out to
            your Partner Relationship manager at Remofirst team.
          </Alert>
          <WorkScheduleTable data={workScheduleQuery.data} />
        </>
      ) : (
        <Alert severity="info">
          No information on the work schedule is available for your country.
          Please reach out to your Partner Relationship Manager at the Remofirst
          team to discuss country-specific guides.
        </Alert>
      )}

      <Box alignSelf="center">
        <Button variant="contained" onClick={onNext}>
          Next
        </Button>
      </Box>
    </Box>
  )
}
