import { Api } from '@api/Api'
import { generateService } from '@core/utils'

export const fetchTimeOff = generateService(async (params) => {
  const { data } = await Api.get(`contracts/absences/`, {
    params,
  })

  return data
})

export const createTimeOff = async (data) =>
  Api.post(`contracts/absences/create/`, data)
export const calculateDayOff = async (data) => {
  return Api.get(
    `contracts/absences/calculate-day-off/?start_date=${
      data.start_date
    }&country=${data.country}
    ${data.region ? `&region=${data.region}` : ''}
    ${data.end_date ? `&end_date=${data.end_date}` : ''}
    ${
      data.start_date_is_half_day
        ? `&start_date_is_half_day=${data.start_date_is_half_day}`
        : ''
    }
        ${
          data.end_date_is_half_day
            ? `&end_date_is_half_day=${data.end_date_is_half_day}`
            : ''
        }`
  )
}
export const cancelTimeOff = async (body) =>
  Api.post(`contracts/absences/${body.id}/status-update/`, { ...body.data })

export const updateTimeOff = async (body) =>
  Api.patch(`contracts/absences/${body.id}/update/`, { ...body.data })

export const updateTimeOffAttachment = async (body) =>
  Api.patch(`contracts/absences/${body.id}/update-attachment/`, {
    ...body.data,
  })

export const updateTimeOffStatus = async (body) =>
  Api.post(`contracts/absences/${body.id}/status-update/`, { ...body.data })

export const downloadTimeOffXLS = async () => {
  const { data } = await Api.get('contracts/absences/export-data/', {
    responseType: 'blob',
  })

  return data
}
