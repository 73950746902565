import './time-off.styles.scss'

import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import Input from '@atoms/Input/Input'
import NotificationBlock from '@atoms/NotificationBlock/NotificationBlock'
import PageTitle from '@atoms/PageTitle/PageTitle'
import Tab from '@atoms/Tabs/libs/Tab/Tab'
import Tabs from '@atoms/Tabs/Tabs'
import Typography from '@atoms/Typography/Typography'
import { TIME_OFF_STATUSES } from '@core/constants'
import { useApp } from '@core/context'
import { useToast } from '@core/hooks/useErrorNotification'
import { useRouteQuery } from '@core/hooks/useRouteQuery'
import { coin, searchIcon } from '@core/icons/icons'
import { TimeOffBalances } from '@features/time-off/time-off-balances/time-off-balances.component'
import { TimeOffRequests } from '@features/time-off/time-off-requests/time-off-requests.component'
import ListHolidaysModal from '@pages/time-off/ListHolidaysModal'
import { TimeOffModal } from '@pages/time-off/time-off-modal/time-off-modal'
import {
  createTimeOff,
  downloadTimeOffXLS,
  fetchTimeOff,
  updateTimeOff,
  updateTimeOffAttachment,
  updateTimeOffStatus,
} from '@services/absences.service'
import moment from 'moment'
import React, { useState } from 'react'
import { HiDownload } from 'react-icons/hi'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { useBoolean, useDebounce } from 'usehooks-ts'

export const TimeOffPage = () => {
  const history = useHistory()
  const { userGroups } = useApp()
  const { successAlert } = useToast()
  const routeQuery = useRouteQuery()
  const queryClient = useQueryClient()
  const newTimOffModalState = useBoolean(
    Boolean(routeQuery.get('openAddModal'))
  )

  const [tab, setTab] = useState(0)
  const [activeTimeOff, setActiveTimeOff] = useState(null)
  const listHolidays = useBoolean(false)
  const editTimOffModalState = useBoolean(false)
  const [createdTimeOffId, setCreatedTimeOffId] = useState(null)
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search)

  const downloadTimeOffRequest = useMutation({
    mutationFn: downloadTimeOffXLS,
    onSuccess: (response) => {
      const href = URL.createObjectURL(response)
      const link = document.createElement('a')
      link.href = href
      link.setAttribute(
        'download',
        `time_off_${moment(new Date()).format('DD_MM_YYYY')}.xls`
      )
      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    },
  })

  const onTabChange = (id) => {
    setTab(id)
  }

  const handleModalOpen = () => {
    newTimOffModalState.setTrue()
  }

  const handleModalClose = () => {
    routeQuery.delete('openAddModal')
    history.replace({
      search: routeQuery.toString(),
    })
    newTimOffModalState.setFalse()
    editTimOffModalState.setFalse()
  }

  const createTimeOffMutation = useMutation({
    mutationFn: (payload) => createTimeOff(payload),
    onSuccess: (response) => {
      if (response.data) {
        setCreatedTimeOffId(response.data.id)
      }
      handleModalClose()
      queryClient.refetchQueries(fetchTimeOff.key)
      successAlert('Successfully created!')
    },
  })

  const updateTimeOffStatusMutation = useMutation({
    mutationFn: (payload) => updateTimeOffStatus(payload),
    onSuccess: () => {
      queryClient.refetchQueries(fetchTimeOff.key)
      successAlert('Successfully updated!')
      handleModalClose()
    },
  })

  const updateTimeOffMutation = useMutation({
    mutationFn: (payload) => updateTimeOff(payload),
    onSuccess: () => {
      handleModalClose()
      queryClient.refetchQueries(fetchTimeOff.key)
      successAlert('Successfully updated!')
    },
  })

  const updateTimeOffAttachmentMutation = useMutation({
    mutationFn: (payload) => updateTimeOffAttachment(payload),
    onSuccess: (response) => {
      if (response.data) {
        setActiveTimeOff(response.data)
      }
      queryClient.refetchQueries(fetchTimeOff.key)
      successAlert('Successfully updated attachment!')
    },
  })

  const handleTimeOffAttachmentDelete = () => {
    const data = {
      id: activeTimeOff.id,
      data: {
        contract: activeTimeOff.contract.id,
        attached_document: null,
      },
    }

    updateTimeOffAttachmentMutation.mutate(data)
  }

  const handleTimeOffAttachmentChange = (file_uuid) => {
    const data = {
      id: activeTimeOff.id,
      data: {
        contract: activeTimeOff.contract.id,
        attached_document: file_uuid,
      },
    }

    updateTimeOffAttachmentMutation.mutate(data)
  }

  const handleTimeOffEdit = (body) => {
    const data = {
      id: activeTimeOff.id,
      data: {
        ...body,
      },
    }
    updateTimeOffMutation.mutate(data)
  }

  const handleTimeOffApprove = () => {
    updateTimeOffStatusMutation.mutate({
      id: activeTimeOff.id,
      data: {
        event_side: 'PARTNER',
        status: 'APPROVED',
      },
    })
  }

  const handleTimeOffReject = (rejectReason) => {
    updateTimeOffStatusMutation.mutate({
      id: activeTimeOff.id,
      data: {
        event_side: 'PARTNER',
        status: 'REJECTED',
        reject_reason: rejectReason,
      },
    })
  }

  const handleRowClick = (data) => {
    console.log(data.request_creator_role)
    editTimOffModalState.setTrue()
    setActiveTimeOff(data)
  }

  const handleSearchChange = (event) => {
    setSearch(event.target.value)
  }

  const isReview = (timeOff) => {
    if (!timeOff) return false
    return (
      timeOff.request_creator_role !== 'PARTNER' && timeOff.status === 'PENDING'
    )
  }

  const isEdit = (timeOff) => {
    if (!timeOff) return false
    return (
      timeOff.request_creator_role === 'PARTNER' && timeOff.status === 'PENDING'
    )
  }

  const isReadOnly =
    activeTimeOff?.status === TIME_OFF_STATUSES.CANCELED ||
    activeTimeOff?.status === TIME_OFF_STATUSES.REJECTED ||
    activeTimeOff?.status === TIME_OFF_STATUSES.APPROVED ||
    activeTimeOff?.status === TIME_OFF_STATUSES.TAKEN

  return (
    <div className="employees-time-page">
      <div className="mb-4">
        <PageTitle>Time off</PageTitle>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div>
          <Input
            data-testid="time-off.component-search"
            onChange={handleSearchChange}
            placeholder="Search"
            type="text"
            name="search"
            value={search}
            endIcon={<Icon icon={searchIcon} />}
            styleClass="time-off__search"
          />
        </div>
        <div className="d-flex align-items-center">
          <Button
            className="ml-2"
            priority="secondary"
            size="small"
            loading={downloadTimeOffRequest.isLoading}
            onClick={downloadTimeOffRequest.mutate}
          >
            <HiDownload className="mr-1" />
            Download report
          </Button>
          <Button
            className="ml-2"
            priority="secondary"
            size="small"
            onClick={handleModalOpen}
          >
            Add time off
          </Button>
        </div>
      </div>
      <NotificationBlock
        render={
          <Typography className="text_regular__14 ml-2">
            Manage your team members time off requests. Head to the following
            link to check the{' '}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <span
              className="link-public-holidays"
              onClick={listHolidays.setTrue}
            >
              Public Holidays
            </span>{' '}
            by country. Time off requests are available for full-time employees
            only.
          </Typography>
        }
        cards={[
          {
            title: 'Create a time off request',
            description:
              'Select Add Time off button, fill in the details and wait for employee to approve or reject',
            icon: coin,
          },
          {
            title: 'Review time off requests',
            description:
              'Approve or reject time off requests created by others',
            icon: coin,
          },
        ]}
      />

      <Tabs className="mt-4" onTabChange={onTabChange} selectedTab={tab}>
        <Tab tabId={0} title="Requests">
          <TimeOffRequests
            search={debouncedSearch}
            onRowClick={handleRowClick}
            onAdd={handleModalOpen}
          />
        </Tab>
        <Tab tabId={1} title="Balances">
          <TimeOffBalances search={debouncedSearch} />
        </Tab>
      </Tabs>

      {newTimOffModalState.value && (
        <TimeOffModal
          title="Add time off"
          onClose={handleModalClose}
          loading={createTimeOffMutation.isLoading}
          onSubmit={(data) => createTimeOffMutation.mutate(data)}
        />
      )}
      {editTimOffModalState.value && (
        <TimeOffModal
          title="Review time off"
          onClose={handleModalClose}
          timeOff={activeTimeOff}
          isEdit={isEdit(activeTimeOff)}
          isReview={isReview(activeTimeOff)}
          isReadOnly={isReadOnly}
          loading={updateTimeOffMutation.isLoading}
          onAttachmentChange={handleTimeOffAttachmentChange}
          onAttachmentDelete={handleTimeOffAttachmentDelete}
          isAttachmentLoading={updateTimeOffAttachmentMutation.isLoading}
          onSubmit={handleTimeOffEdit}
          onApprove={handleTimeOffApprove}
          isLoadingApprove={updateTimeOffStatusMutation.isLoading}
          onReject={handleTimeOffReject}
          isLoadingReject={updateTimeOffStatusMutation.isLoading}
        />
      )}

      {listHolidays.value && (
        <ListHolidaysModal onClose={listHolidays.setFalse} />
      )}
    </div>
  )
}
