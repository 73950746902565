/* eslint-disable no-underscore-dangle */

import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { logoutIcon } from '@core/icons/icons'
import classNames from 'classnames'
import React from 'react'
import { MdCheck } from 'react-icons/md'

import Step from './Step'
import StepContent from './StepContent'
import styles from './stepper.module.scss'

/**
 * @param {string} title required
 * @param {string} description required
 * @param {number} activeStep required
 * @param {JSX.Element} children
 */
const Stepper = ({ title, description, logout, activeStep, ...props }) => {
  const children = props.children.filter((c) => !!c)
  return (
    <div className="d-flex justify-content-between w-100 h-100">
      <aside
        style={{ paddingTop: 40, paddingLeft: 32 }}
        className="col-xs-12 col-sm-5 col-md-4 col-lg-3 col-xl-3 pb-3 overflow-auto"
      >
        <div className={styles.stepsBody}>
          <div style={{ marginBottom: 50 }}>
            <img src="/assets/icons/logo_2.0.svg" alt="" />
          </div>
          <Typography as="p" className="heading_semibold__24 mb-3">
            {title}
          </Typography>
          <Typography
            className="text_regular__14 color_grey"
            style={{ marginBottom: 64 }}
          >
            {description}
          </Typography>

          {React.Children.map(children, (child, idx) => {
            if (!child) {
              return React.Fragment
            }

            const StepElement = React.cloneElement({
              ...child,
              props: {
                ...child.props,
                children: '',
                step: idx + 1,
                disabled: activeStep < idx + 1,
              },
            })

            return (
              <>
                <div className="d-flex align-items-center">
                  <div
                    style={{
                      maxWidth: '32px',
                      minWidth: '32px',
                      height: '32px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '50%',
                      border: `2px solid ${
                        activeStep === idx + 1 ? '#000000' : '#B2B2B2'
                      }`,
                      boxSizing: 'border-box',
                    }}
                  >
                    <Typography
                      as="p"
                      className={classNames([
                        'heading_semibold__16',
                        activeStep === idx + 1 ? 'color_black' : 'color_grey',
                      ])}
                    >
                      {activeStep > idx + 1 ? (
                        <MdCheck color="#40b84c" />
                      ) : (
                        idx + 1
                      )}
                    </Typography>
                  </div>

                  <div
                    className={classNames([
                      'flex-shrink-0 ml-2 heading_semibold__18',
                      activeStep === idx + 1 ? 'color-black' : 'color_grey',
                    ])}
                  >
                    {StepElement}
                  </div>
                </div>
                <div
                  className="d-flex justify-content-center"
                  style={{ width: 32 }}
                >
                  {idx !== children.length - 1 && (
                    <div
                      style={{
                        height: 32,
                        width: 2,
                        background: '#B2B2B2',
                      }}
                    />
                  )}
                </div>
              </>
            )
          })}
        </div>
        {logout && (
          <div className={styles.stepsFooter}>
            <button
              data-testid="Stepper-25C26A"
              type="button"
              className={styles.logout}
              onClick={logout}
            >
              <Icon icon={logoutIcon} fill="#878787" />
              <Typography className="text_regular__14 ml-2">Logout</Typography>
            </button>
          </div>
        )}
      </aside>
      <main className="col" style={{ overflow: 'auto' }}>
        {React.Children.map(children, (child, idx) => {
          if (!child) {
            return React.Fragment
          }

          return (
            <div
              className={classNames([
                'w-100 h-100',
                activeStep !== idx + 1 && 'd-none',
              ])}
            >
              {child.props.children}
            </div>
          )
        })}
      </main>
    </div>
  )
}

export default Object.assign(Stepper, {
  Step,
  StepContent,
})
