import './OverviewPage.scss'

import Icon from '@atoms/Icon/Icon'
import { AppContext } from '@core/context'
import { arrowUp } from '@core/icons/icons'
import { numberWithSpaces } from '@core/utils'
import CalendarStepper from '@pages/overview/CalendarStepper'
import NumberCard from '@pages/overview/NumberCard'
import { getFeatureFlagList } from '@services/feature-flag.service'
import { getOnboardingChecklist, getStats } from '@services/profile.service'
import { DotWave } from '@uiball/loaders'
import classNames from 'classnames'
import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { getOverviews } from './mock'
import { OnboardingChecklist } from './onboarding-checklist/onboarding-checklist.component'

const CustomTooltip = ({ active, payload, label, currency }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p>Revenue of {label}</p>
        <p>
          {numberWithSpaces(payload[0].value)}
          {currency?.sign}
        </p>
      </div>
    )
  }

  return null
}

const DataFormater = (number) => {
  if (number > 1000000000) {
    return `${(number / 1000000000).toString()}B`
  }
  if (number > 1000000) return `${(number / 1000000).toString()}M`
  if (number > 1000) return `${(number / 1000).toString()}K`
  return number.toString()
}

export default function OverviewPage() {
  const { profile } = useContext(AppContext)
  const { data, isLoading } = useQuery('stats', () => getStats())
  const { data: featureFlag } = useQuery('getFeatureFlagList', () =>
    getFeatureFlagList()
  )
  const getFeature = featureFlag?.filter(
    (ff) => ff.feature_name === 'partner_timeline'
  )
  const showFeature =
    getFeature &&
    getFeature[0] &&
    (getFeature[0].partners_full_access ||
      getFeature[0].partners.some(
        (partner) => partner.id === profile.partner_profile_id
      ))
  const onboardingChecklist = useQuery([getOnboardingChecklist.key], {
    queryFn: () => getOnboardingChecklist.fetch(profile.id),
    enabled: !!profile.id,
  })

  return (
    <div className="overview-page">
      <h1 className="overview-page__title">Dashboard</h1>

      {onboardingChecklist.data && (
        <div style={{ width: 640 }} className="mb-5">
          <h4 className="mb-4 font-weight-normal">
            Please complete this checklist to begin working together!
          </h4>
          <OnboardingChecklist
            passedPercent={onboardingChecklist.data?.passed_percent}
            checkList={onboardingChecklist.data.check_list}
          />
        </div>
      )}

      {showFeature && <CalendarStepper />}
      {isLoading ? (
        <div className="d-flex w-100 h-100 align-items-center justify-content-center">
          <DotWave size={48} speed={1} color="black" />
        </div>
      ) : (
        <div>
          <div className="overview-page__chart-info">
            <div className="chart-info">
              <div className="chart-info__title">This month’s Revenue</div>
              <div className="chart-info__amount">
                {numberWithSpaces(data?.payrolls_stats?.amount)}
                {data?.payrolls_stats?.currency?.sign}
              </div>
              <div className="chart-info__description">
                <span
                  className={
                    data?.payrolls_stats?.is_more
                      ? 'chart-info__description--success'
                      : 'chart-info__description--danger'
                  }
                >
                  {data?.payrolls_stats?.percentage !== 0 && (
                    <span>
                      <Icon icon={arrowUp} />
                    </span>
                  )}
                  <span
                    className={classNames({
                      'chart-info__description--gray':
                        data?.payrolls_stats?.percentage === 0,
                    })}
                  >
                    {data?.payrolls_stats?.percentage}%
                  </span>
                </span>
                <span>vs last month</span>
              </div>
            </div>
          </div>

          <div className="overview-page__chart">
            {data?.payrolls_stats && (
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  width={500}
                  height={300}
                  data={data?.payrolls_stats?.stats}
                >
                  <CartesianGrid vertical={false} />
                  <XAxis
                    dataKey="month"
                    fontSize="12px"
                    color="#878787"
                    tickLine={false}
                    tickMargin={8}
                  />
                  <YAxis
                    dataKey="amount"
                    tickCount={6}
                    unit={data?.payrolls_stats?.currency?.sign}
                    fontSize="12px"
                    fontFamily="Inter"
                    tickFormatter={DataFormater}
                    color="#878787"
                    axisLine={false}
                    tickLine={false}
                    tickMargin={8}
                  />
                  <Tooltip
                    content={
                      <CustomTooltip
                        currency={data?.payrolls_stats?.currency}
                      />
                    }
                  />
                  <Line
                    type="monotone"
                    dataKey="amount"
                    stroke="#40b84c"
                    strokeWidth={3}
                    activeDot={{ r: 8 }}
                    dot={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            )}
          </div>
          <div className="overview-page__cards">
            {getOverviews(data).map((overview) => (
              <NumberCard
                key={overview.title}
                image={overview.image}
                number={overview.number}
                title={overview.title}
                link={overview.link}
                subtitle={overview.description}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
