import NotFound from '@atoms/NotFound/NotFound'
import Select from '@atoms/Select/Select'
import { AbsoluteSpinner } from '@atoms/Spinner/AbsoluteSpinner'
import CustomTable from '@atoms/Table/CustomTable'
import { Loader } from '@components/loader/loader.component'
import { TIME_OFF_LEAVE_TYPES } from '@core/constants'
import { useApp } from '@core/context'
import usePagination from '@core/hooks/usePagination'
import { formatDate } from '@core/utils'
import { leaveTypes, reasonTypes } from '@features/time-off/time-off.data'
import { fetchTimeOff } from '@services/absences.service'
import { getTimeOffBalances } from '@services/time-off.service'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import { useBoolean } from 'usehooks-ts'

import { TimeOffBalanceBreakdown } from '../time-off-balance-breakdown/time-off-balance-breakdown.component'

const ColoredNumber = styled.span`
  color: ${({ value }) => {
    if (value < 0) return '#E0613A'
    if (value > 0) return '#137547'
    return 'inherit'
  }};
`

export const timeOffTableFields = [
  {
    title: 'Name',
    key: 'contract.user_info.full_name',
    type: 'avatar',
  },
  {
    title: 'Country',
    key: 'contract.working_country.name',
    type: 'string',
  },
  {
    title: 'Vacation year',
    type: 'custom',
    render: ({ contract }) => {
      return (
        <span>
          {`${formatDate(
            contract.working_country?.vacation_year_start
          )} - ${formatDate(contract.working_country?.vacation_year_end)}`}
        </span>
      )
    },
  },
  {
    title: 'Annual entitlement',
    type: 'custom',
    render: (item) => (
      <span>
        {item.unlimited_time_off_on
          ? 'Unlimited'
          : item.annual_days_by_contract}
      </span>
    ),
  },
  {
    title: 'Accrued to date',
    type: 'custom',
    render: ({ unlimited_time_off_on, accrued_days }) =>
      unlimited_time_off_on ? (
        '-'
      ) : (
        <ColoredNumber value={accrued_days}>{accrued_days}</ColoredNumber>
      ),
  },
  {
    title: 'Carryover',
    type: 'custom',
    render: ({ unlimited_time_off_on, carryover_days }) =>
      unlimited_time_off_on ? (
        '-'
      ) : (
        <ColoredNumber value={carryover_days}>{carryover_days}</ColoredNumber>
      ),
  },
  {
    title: 'Adjustment',
    type: 'custom',
    render: ({ unlimited_time_off_on, ad_hoc_days }) =>
      unlimited_time_off_on ? (
        '-'
      ) : (
        <ColoredNumber value={ad_hoc_days}>{ad_hoc_days}</ColoredNumber>
      ),
  },
  {
    title: 'Taken',
    type: 'custom',
    render: ({ taken }) => (
      <ColoredNumber value={taken}>{taken ?? 0}</ColoredNumber>
    ),
  },
  {
    title: 'Upcoming',
    type: 'custom',
    render: ({ upcoming }) => (
      <ColoredNumber value={upcoming}>{upcoming ?? 0}</ColoredNumber>
    ),
  },
  {
    title: 'Balance',
    type: 'custom',
    render: ({ unlimited_time_off_on, available_days }) =>
      unlimited_time_off_on ? (
        <span>&#8734;</span>
      ) : (
        <ColoredNumber value={available_days}>{available_days}</ColoredNumber>
      ),
  },
]

export const TimeOffBalances = ({ search, onAdd }) => {
  const { countries, userGroups } = useApp()
  const [countryId, setCountryId] = useState(null)
  const { page, limit, setPage } = usePagination({ page: 1, limit: 10 })
  const [selectedBalance, setSelectedBalance] = useState(null)
  const [selectedAbsence, setSelectedAbsence] = useState(null)

  const {
    isLoading,
    isFetching,
    data: balancesData,
  } = useQuery([getTimeOffBalances.key, countryId, page, search], {
    queryFn: () =>
      getTimeOffBalances.fetch({
        country: countryId,
        limit,
        offset: (page - 1) * limit,
        search,
      }),
    keepPreviousData: true,
  })

  const { data: timeOffData } = useQuery(
    [fetchTimeOff.key, countryId, page, search],
    {
      queryFn: () =>
        fetchTimeOff.fetch({
          current_country: countryId,
          limit,
          offset: (page - 1) * limit,
          search,
        }),
      keepPreviousData: true,
    }
  )

  const handleCountryChange = (option) => {
    setCountryId(option?.value || null)
    setPage(1)
  }

  const handleRowClick = (data) => {
    setSelectedBalance(data)
    setSelectedAbsence(
      timeOffData?.results.find((t) => t.contract.id === data.contract.id)
    )
  }

  const handleBreakdownClose = () => {
    setSelectedBalance(null)
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      <div className="timeOff-table-box">
        <div className="timeOff-table-wrap">
          {balancesData?.count > 0 ? (
            <>
              <div className="d-flex mb-4 align-items-center gap-3">
                <div style={{ width: 190 }}>
                  <Select
                    placeholder="Select country"
                    value={countryId}
                    onChange={handleCountryChange}
                    options={[
                      { value: 0, label: 'All' },
                      ...countries.map((country) => ({
                        value: country.id,
                        label: country.name,
                      })),
                    ]}
                    isClearable
                  />
                </div>
              </div>
              <AbsoluteSpinner isFetching={isFetching}>
                <CustomTable
                  fields={timeOffTableFields}
                  page={page}
                  total={balancesData.count}
                  onPage={setPage}
                  pageSize={limit}
                  data={balancesData.results}
                  onRowClick={handleRowClick}
                />
              </AbsoluteSpinner>
            </>
          ) : (
            <NotFound
              title="It's quiet in here ..."
              description="There are no time off requests "
              action="Add time off"
              onClickAction={onAdd}
            />
          )}
        </div>
      </div>
      {selectedBalance && (
        <TimeOffBalanceBreakdown
          item={selectedBalance}
          absence={selectedAbsence}
          onClose={handleBreakdownClose}
        />
      )}
    </>
  )
}
