import { Api } from '@api/Api'
import { NoticePeriod } from '@remoteam-front/types/v2'

import { generateService } from '../utils'

export const getNoticePeriods = generateService(async (countyId: number) => {
  const { data } = await Api.get<NoticePeriod[]>(
    `/countries/${countyId}/notice-periods-list/`
  )
  return data
})
