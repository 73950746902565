import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import Select from '@atoms/Select/Select'
import ToastWithActions from '@atoms/ToastWithActions/ToastWithActions'
import Typography from '@atoms/Typography/Typography'
import { useApp } from '@core/context'
import { searchIcon } from '@core/icons/icons'
import { monthOptions } from '@pages/additional-payments/utils'
import { fetchCompanies } from '@pages/customers/CustomersPage.service'
import { downloadExpenseReceipts } from '@services/expense.service'
import moment from 'moment/moment'
import React, { useCallback, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { useBoolean } from 'usehooks-ts'

const StyledModal = styled(Modal)`
  .modal-content {
    padding: 16px 10px;
    border-radius: 4px !important;
  }

  .modal-header {
    margin-bottom: 0;
    align-items: center;
  }
`

const SpacedSelectWrapper = styled.div`
  margin-top: 16px;
`

const StyledButton = styled(Button)`
  border-radius: 4px !important;
`

const DownloadExpenseReceiptsComponent = ({ onClose }) => {
  const allAddPmtDownloadChecked = useBoolean()
  const [companies, setCompanies] = useState([])
  const [date, setDate] = useState(null)
  const { profile } = useApp()
  const lastPayloadRef = useRef(null)

  const clear = () => {
    setDate('')
    setCompanies([])
  }

  const downloadCSVRequest = useMutation({
    mutationFn: downloadExpenseReceipts,
    onSuccess: (response) => {
      const href = URL.createObjectURL(response)
      const link = document.createElement('a')
      link.href = href
      link.setAttribute(
        'download',
        `Expense_Receipts_${moment(new Date()).format('DD_MM_YYYY')}.zip`
      )
      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      URL.revokeObjectURL(href)
      clear()
      onClose()
    },
    onError: () => {
      toast(
        <ToastWithActions
          type="error"
          title="Download Failed"
          message="The expense receipt download could not be completed. Please check your internet connection or try again later. If the issue persists, contact support."
          actions={[
            {
              label: 'Cancel',
              variant: 'secondary',
              onClick: () => {
                toast.dismiss()
                onClose()
              },
            },
            {
              label: 'Retry',
              variant: 'primary',
              onClick: handleRetry,
            },
          ]}
        />,
        {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          theme: 'colored',
        }
      )
    },
  })

  const { isLoading: isLoadingCompany, data: companyData } = useQuery(
    ['companies'],
    {
      queryFn: () => fetchCompanies('active', undefined, 1, 1000),
    }
  )

  const buildPayload = useCallback(() => {
    if (!date) return null

    const selectedDate = moment(date.value, 'YYYY-MM-DD') || undefined
    const start_date = selectedDate.startOf('month').toISOString() || undefined
    const end_date = selectedDate.endOf('month').toISOString() || undefined

    return {
      zip_name: 'receipts_zip_file',
      start_date,
      end_date,
      company_ids: companies.map((e) => e.value),
      partner_id: profile.partner_profile_id,
    }
  }, [date, companies, profile.partner_profile_id])

  const onSubmit = (event) => {
    event.preventDefault()

    const payload = buildPayload()
    if (!payload) {
      return
    }

    lastPayloadRef.current = payload
    downloadCSVRequest.mutate(payload)
  }

  const handleRetry = () => {
    if (lastPayloadRef.current) {
      downloadCSVRequest.mutate(lastPayloadRef.current)
    }
    toast.dismiss()
  }

  return (
    <StyledModal show onHide={onClose} centered>
      <Modal.Header closeButton>
        <Typography className="heading_semibold__24">
          Download expense receipts
        </Typography>
      </Modal.Header>
      <Modal.Body>
        <form className="d-flex flex-column" onSubmit={onSubmit}>
          <Select
            data-testid="DownloadExpenseReceipts-D60EB5"
            onChange={setDate}
            value={date}
            required
            label={
              <span>
                Payout month <span style={{ color: 'red' }}>*</span>
              </span>
            }
            placeholder="Select"
            options={monthOptions(
              moment().startOf('year').format('YYYY-MM-DD')
            )}
          />
          <SpacedSelectWrapper>
            <Select
              data-testid="DownloadExpenseReceipts-D60EB5"
              isMulti
              withoutCaret
              startIcon={<Icon icon={searchIcon} />}
              onChange={setCompanies}
              value={companies}
              isDisabled={isLoadingCompany || allAddPmtDownloadChecked.value}
              label="Company"
              placeholder="Select"
              options={
                companyData
                  ? companyData?.results.map((company) => ({
                      value: company.id,
                      label: company.name,
                    }))
                  : []
              }
            />
          </SpacedSelectWrapper>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <StyledButton
          data-testid="DownloadExpenseReceipts-E86EC4"
          size="small"
          onClick={onSubmit}
          disabled={downloadCSVRequest.isLoading}
          loading={downloadCSVRequest.isLoading}
        >
          Download expense
        </StyledButton>
      </Modal.Footer>
    </StyledModal>
  )
}

export default DownloadExpenseReceiptsComponent
