import { ProbationPeriodTable } from '@components/probation-period-table/probation-period-table.component'
import { Alert, Box, Button } from '@mui/material'
import { getProbationPeriod } from '@remoteam-front/services'
import { DotWave } from '@uiball/loaders'
import { useQuery } from 'react-query'

type Props = {
  countryId: number
  onNext(): void
}

export const ProbationPeriod = ({ countryId, onNext }: Props) => {
  const probationPeriodQuery = useQuery([getProbationPeriod.key], {
    enabled: !!countryId,
    queryFn: () => getProbationPeriod.fetch(countryId),
  })

  if (probationPeriodQuery.isLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <DotWave />
      </Box>
    )
  }

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Alert severity="info">
        If you spot any mistake in the table below, please reach out to your
        Partner Relationship manager at Remofirst team
      </Alert>
      <ProbationPeriodTable data={probationPeriodQuery.data} />

      <Box alignSelf="center">
        <Button variant="contained" onClick={onNext}>
          Next
        </Button>
      </Box>
    </Box>
  )
}
