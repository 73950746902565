/* eslint-disable */
import axios from 'axios'
import { makeUseAxios } from 'axios-hooks'
import Cookies from 'js-cookie'
import { toast } from 'react-toastify'
import { ACCESS_TOKEN_NAME } from 'src/AuthProvider'

const BASE_URL = process.env.REACT_APP_BASE_URL
const COST_CALCULATION_API = process.env.REACT_APP_COST_CALCULATION_API
const BASE_PURE_API = process.env.REACT_APP_BASE_PURE_API
console.log(BASE_PURE_API)
export const PORT_URL = process.env.REACT_APP_BFF_API

const customAxios = axios.create({
  baseURL: BASE_URL,
})

const customAxiosNoResponse = axios.create({
  baseURL: BASE_URL,
})
const costCalculatorAxiosNoResponse = axios.create({
  baseURL: COST_CALCULATION_API,
})
const axiosBasePure = axios.create({
  baseURL: BASE_PURE_API,
})

const requestInterceptor = (request) => {
  const ACCESS_TOKEN = localStorage.getItem(ACCESS_TOKEN_NAME)
  request.headers = {
    ...request.headers,
    Authorization: `Bearer ${ACCESS_TOKEN}`,
  }
  return request
}

customAxios.interceptors.request.use(requestInterceptor)

costCalculatorAxiosNoResponse.interceptors.request.use(requestInterceptor)

axiosBasePure.interceptors.request.use(requestInterceptor)

customAxios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config } = error
    if (error.response.status === 401 && !config._retry) {
      config._retry = true
      Cookies.set('redirect_url', window.location.href, {
        domain: '.remofirst.com',
      })
      window.location.href = `${PORT_URL}/auth/logout`
    }

    if (error.response?.data?.errors?.length) {
      error.response.data.errors.forEach((response) => {
        toast.error(response.message, {
          position: toast.POSITION.TOP_RIGHT,
          theme: 'colored',
        })
      })
    } else {
      toast.error(error?.message || 'Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      })
    }

    return Promise.reject(error)
  }
)

customAxiosNoResponse.interceptors.request.use(requestInterceptor)

export const useAxiosCall = makeUseAxios({
  axios: customAxios,
})

export const Api = customAxios
export const ApiCostCalculator = costCalculatorAxiosNoResponse
export const ApiNoResInterceptor = customAxiosNoResponse
export const ApiBasePure = axiosBasePure
