import './onboarding-checklist.styles.scss'

import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { useApp } from '@core/context'
import {
  check,
  checklistDocumentIcon,
  externalLinkIcon,
} from '@core/icons/icons'
import { Button } from '@mui/material'
import { ComplianceStatus } from '@pages/profile/compliance-tab/compliance-tab'
import { patchCostCalcReviewTime } from '@services/app.service'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import { useMutation } from 'react-query'
import { useBoolean } from 'usehooks-ts'
import { v4 } from 'uuid'

import ComplianceModal from './compliance-modal/compliance-modal.component'
import { CountryGuideModal } from './country-guide/country-guide-modal.component'
import { CountryGuideStepper } from './country-guide/country-guide-stepper/country-guide-stepper.component'
import EmployerCostModalComponent from './employer-cost-modal/employer-cost-modal.component'
import { KeyContactsBillingInfoModal } from './key-contacts-billing-info/key-contacts-billing-info-modal.component'
import { KeyContactsBillingInfoStepper } from './key-contacts-billing-info/key-contacts-billing-info-stepper/key-contacts-billing-info-stepper.component'
import SumsubModalComponent from './sumsub-modal/sumsub-modal.component'

const DoneButton = (
  <div className="d-flex gap-1 status status_success">
    <Icon
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      icon={check}
    />
    Done
  </div>
)
const SubmittedButton = (
  <div className="d-flex gap-1 status status_success">
    <Icon
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      icon={check}
    />
    Submitted
  </div>
)

const StartButton = (onClick) => (
  <Button variant="contained" size="small" onClick={onClick}>
    Start
  </Button>
)
export const OnboardingChecklist = ({ passedPercent, checkList }) => {
  const { profile } = useApp()

  const NdaDpaDone = !!checkList.nda_dpa?.passed
  const costCalculatorDone = !!checkList.cost_calculator?.passed
  const complianceDone = !!checkList.compliance?.passed
  const countryStatusDone = !!checkList.country_guide?.passed
  const keyContactsBillingInfoDone =
    !!checkList.key_contacts_billing_info?.passed

  const employerCostDpa = useBoolean(false)
  const openCompliance = useBoolean(false)
  const openSumSub = useBoolean(false)
  const countryGuideDialog = useBoolean(false)
  const countryGuideStepper = useBoolean(false)
  const keyContactsBillingInfoStepper = useBoolean(false)

  const keyContactsBillingInfoDialog = useBoolean(false)

  const patchCostCalcReview = useMutation({
    mutationFn: () => patchCostCalcReviewTime(profile.id),
  })

  const onOpenEmployerCostLink = () => {
    if (checkList.cost_calculator.link) {
      if (!checkList.cost_calculator.in_review_time) {
        patchCostCalcReview.mutate()
      }
      window.open(
        checkList.cost_calculator.link,
        '_blank',
        'noopener,noreferrer'
      )
    }
  }

  const renderComplianceButton = (status, setTrue) => {
    if (status === 'submitted') {
      return SubmittedButton
    }

    if (status === 'start' || status === 'retry') {
      return StartButton(setTrue)
    }

    return <ComplianceStatus status={status || 'start'} />
  }

  const steps = [
    ...(NdaDpaDone
      ? [
          {
            title: 'Non-Disclosure & Data Processing Agreements',
            actions: [DoneButton],
          },
        ]
      : []),
    {
      title: 'Country guide',
      actions: [
        countryStatusDone
          ? DoneButton
          : StartButton(countryGuideDialog.setTrue),
      ],
    },
    {
      title: 'Employer costs',
      actions: [
        <Button
          hidden={!checkList.cost_calculator.in_review_time}
          key={1}
          size="small"
          priority="primary"
          onClick={onOpenEmployerCostLink}
        >
          <div className="d-flex gap-1">
            Access the file
            <Icon icon={externalLinkIcon} viewBox="0 0 20 20" width="12px" />
          </div>
        </Button>,
        costCalculatorDone ? (
          DoneButton
        ) : (
          <Button
            key={2}
            variant="contained"
            size="small"
            disabled={!!checkList.cost_calculator.in_review_time}
            onClick={employerCostDpa.setTrue}
          >
            {checkList.cost_calculator.in_review_time ? 'in review' : 'Start'}
          </Button>
        ),
      ],
    },
    {
      title: 'Company compliance',
      actions: [
        complianceDone
          ? DoneButton
          : renderComplianceButton(
              checkList.compliance?.current_status,
              openCompliance.setTrue
            ),
      ],
    },
    {
      title: 'Key contacts and billing information',
      actions: [
        keyContactsBillingInfoDone
          ? DoneButton
          : StartButton(keyContactsBillingInfoDialog.setTrue),
      ],
    },
  ]

  const onCompleteCompliance = () => {
    openCompliance.setFalse()
    openSumSub.setTrue()
  }

  return (
    <div className="onboarding-checklist">
      <div className="d-flex align-items-center gap-4">
        <CircularProgressbar
          value={passedPercent}
          strokeWidth={5}
          text={`${Math.ceil(passedPercent)}%`}
          styles={{
            ...buildStyles({
              pathColor: '#40b84c',
              trailColor: 'rgba(38, 40, 66, 0.08)',
            }),
            root: {
              width: '56px',
              height: '56px',
            },
            text: {
              fontWeight: 700,
              fontSize: '25px',
              textAnchor: 'middle',
              dominantBaseline: 'middle',
            },
          }}
        />
        <div>
          <Typography className="heading_semibold__16 onboarding-checklist__title">
            Onboarding checklist
          </Typography>
          <Typography className="text_regular__14 color_grey mt-2">
            You must complete all of the steps to activate your account.
          </Typography>
        </div>
      </div>
      <div className="d-flex flex-column gap-1 mt-3">
        {steps.map((step) => (
          <Step key={step.title} {...step} />
        ))}
      </div>
      {employerCostDpa.value && (
        <EmployerCostModalComponent
          onClose={employerCostDpa.setFalse}
          onSave={onOpenEmployerCostLink}
        />
      )}
      {openCompliance.value && (
        <ComplianceModal
          onClose={openCompliance.setFalse}
          onSave={onCompleteCompliance}
        />
      )}
      {openSumSub.value && (
        <SumsubModalComponent onClose={openSumSub.setFalse} />
      )}
      {countryGuideDialog.value && (
        <CountryGuideModal
          onClose={countryGuideDialog.setFalse}
          onSave={() => {
            countryGuideDialog.setFalse()
            countryGuideStepper.setTrue()
          }}
        />
      )}
      {keyContactsBillingInfoDialog.value && (
        <KeyContactsBillingInfoModal
          onClose={keyContactsBillingInfoDialog.setFalse}
          onSave={() => {
            keyContactsBillingInfoDialog.setFalse()
            keyContactsBillingInfoStepper.setTrue()
          }}
        />
      )}
      {countryGuideStepper.value && (
        <CountryGuideStepper onClose={countryGuideStepper.setFalse} />
      )}
      {keyContactsBillingInfoStepper.value && (
        <KeyContactsBillingInfoStepper
          onClose={keyContactsBillingInfoStepper.setFalse}
        />
      )}
    </div>
  )
}

const Step = ({ title, disabled, onClick, done, status, actions }) => {
  return (
    <div className="step" disabled={disabled} key={title}>
      <div className="step__title">
        <div className="step__icon">
          <Icon icon={checklistDocumentIcon} />
        </div>
        <div>
          <Typography variant="textLarge1">{title}</Typography>
        </div>
      </div>
      {status === 'submitted' && (
        <div className="d-flex gap-1 status status_success">
          <Icon
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            icon={check}
          />
          Submitted
        </div>
      )}
      <div className="d-flex align-items-center gap-2">
        {actions?.map((action) => action)}
      </div>
    </div>
  )
}
