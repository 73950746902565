/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import styled from 'styled-components'

const Div = styled.div`
  display: flex;
  input[type='checkbox'] {
    display: none;
  }

  label {
    background-color: #ccc;
    border-radius: 50px;
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: all ease-in-out 0.2s;
    width: 45px;
    height: 25px;
  }
  label::after {
    background-color: #fff;
    border-radius: 50%;
    content: ' ';
    cursor: pointer;
    display: inline-block;
    position: absolute;
    left: 2px;
    top: 2px;
    transition: all ease-in-out 0.2s;
    width: 20px;
    height: 20px;
  }
  input[type='checkbox']:checked ~ label {
    background-color: #40b84c;
  }

  input[type='checkbox']:checked ~ label::after {
    background-color: #fff;
    transform: translateX(20px);
  }
`

export default React.forwardRef((props, ref) => {
  return (
    <Div>
      <input
        data-testid="Toggle-index-001CE0"
        ref={ref}
        {...props}
        type="checkbox"
        id={props.id || 'toggle'}
      />
      <label htmlFor={props.id || 'toggle'} />
    </Div>
  )
})
