import 'bootstrap/dist/css/bootstrap.min.css'

import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import { Spinner } from '@atoms/Spinner/spinner'
import Typography from '@atoms/Typography/Typography'
import { emptyCircle, errorCircle, infoIcon, success } from '@core/icons/icons'
import {
  getPayrollList,
  getPayrollListNeedChange,
} from '@services/payroll.service'
import classNames from 'classnames'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

const ProgressBar = styled('div')`
  width: ${(p) => `${p.procentage}`};
  background: ${(p) => (p.active ? '#40b84c' : '#EBEBEB')};
  height: 1px;
  display: block;
`

const months = {
  August: {
    from: 19,
    to: 25,
  },
  September: {
    from: 17,
    to: 26,
  },
  October: {
    from: 16,
    to: 25,
  },
  November: {
    from: 18,
    to: 27,
  },
  December: {
    from: 16,
    to: 27,
  },
}

function addWorkingDays(startDate, days) {
  const currentDate = new Date(startDate)
  let addedDays = 0

  while (addedDays < days) {
    currentDate.setDate(currentDate.getDate() + 1)

    // Check if the current day is a weekday (Monday to Friday) and not a holiday
    if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
      // eslint-disable-next-line no-plusplus
      addedDays++
    }
  }

  return currentDate
}

function lastBusinessDayOfMonth(year, month) {
  let offset = 0
  let result = null

  do {
    result = new Date(year, month, offset)

    // eslint-disable-next-line no-plusplus
    offset--
  } while (result.getDay() === 0 || result.getDay() === 6)

  return result
}

function calculateElapsedPercentage(startDate, endDate) {
  const start = new Date(startDate)
  const end = new Date(endDate)
  const now = new Date()

  // Ensure that the current date is within the range
  if (now < start) return 0
  if (now > end) return 100

  const totalDuration = end - start
  const elapsedDuration = now - start

  const percentage = (elapsedDuration / totalDuration) * 100

  return percentage.toFixed(2) // Return percentage with 2 decimal places
}

const CalendarStepper = () => {
  const history = useHistory()
  const [payrollsNotApproved, setPayrollsNotApproved] = useState(false)

  const today = new Date()
  const currentMonthFirstDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    1
  )
  const monthString = moment(today).format('MMMM')
  const currentMonthLastDate = lastBusinessDayOfMonth(
    today.getFullYear(),
    today.getMonth() + 1
  )
  const thirdWorkDays = addWorkingDays(currentMonthFirstDate, 2)
  const fourthWorkDays = addWorkingDays(currentMonthFirstDate, 3)
  const sevenWorkDays = addWorkingDays(currentMonthFirstDate, 6)
  const sixWorkDays = addWorkingDays(currentMonthFirstDate, 5)
  const endDueDate = addWorkingDays(currentMonthFirstDate, 7)
  const weekDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    months[monthString].from
  )
  const cutOffDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    months[monthString].to
  )
  const { data, isLoading } = useQuery(['payrolls'], {
    queryFn: () => getPayrollList({ limit: 100000, page: 1 }),
    keepPreviousData: true,
  })

  const { data: listNeedChange, isLoading: changeLoading } = useQuery(
    'payrollsListNeedChange',
    {
      queryFn: () => getPayrollListNeedChange(),
    }
  )

  useEffect(() => {
    if (data) {
      const allPayrrols = []
      data.forEach((d) => {
        d?.current?.payrolls.forEach((p) => allPayrrols.push(p))
      })
      if (
        allPayrrols?.filter((p) => p.state === 'created').length > 0 ||
        listNeedChange?.count > 0
      ) {
        setPayrollsNotApproved(true)
      }
    }
  }, [data, listNeedChange])

  return (
    <div
      className="d-flex flex-column overview-page-stats mb-3"
      style={{ border: '1px solid green' }}
    >
      <div className="d-flex align-items-center mb-3">
        <Typography className="heading_semibold__16 mr-2">
          Payroll Timeline
        </Typography>
        <Typography className="heading_semibold__16 color_primary mr-2">
          {moment(today).format('MMMM YYYY')}
        </Typography>
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip>
              The dates below are to guarantee your team get paid on time; if
              you encounter any special difficulty please reach out to your
              customer success manager. Please note if you have new team member
              joining later this month, payroll dates might be impacted but stay
              assured that our team will guide you through.
            </Tooltip>
          }
        >
          <span style={{ marginLeft: 4, verticalAlign: 'top' }}>
            <Icon icon={infoIcon} style={{ fill: '#878787' }} />
          </span>
        </OverlayTrigger>
      </div>

      <div className="d-flex">
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center">
            <Icon
              icon={
                calculateElapsedPercentage(thirdWorkDays, fourthWorkDays) ===
                100
                  ? success
                  : emptyCircle
              }
            />
            <ProgressBar
              procentage={`${calculateElapsedPercentage(
                thirdWorkDays,
                fourthWorkDays
              )}%`}
              active
            />
            <ProgressBar
              procentage={`${
                100 - calculateElapsedPercentage(thirdWorkDays, fourthWorkDays)
              }%`}
            />
          </div>
          <div className="mt-2 pr-4">
            <Typography className="heading_semibold__16">
              Additional payments & expenses deadline
            </Typography>
            <Typography className="text_medium__14 mt-2">
              {moment(thirdWorkDays).format('ddd')} {thirdWorkDays.getDate()}
            </Typography>
            <Typography className="text_light__12 mt-2 color_grey">
              All client approved expenses and additional payments added before{' '}
              {moment(thirdWorkDays).format('ddd')} {thirdWorkDays.getDate()}{' '}
              will go into {moment(thirdWorkDays).format('MMMM')} payroll
            </Typography>
          </div>
        </div>
        <Spinner isFetching={isLoading || changeLoading}>
          <div className="d-flex flex-column">
            <div className="d-flex align-items-center">
              {payrollsNotApproved && sixWorkDays <= new Date() ? (
                <Icon icon={errorCircle} />
              ) : (
                <Icon
                  icon={
                    calculateElapsedPercentage(fourthWorkDays, endDueDate) ===
                    100
                      ? success
                      : emptyCircle
                  }
                />
              )}
              <ProgressBar
                procentage={`${calculateElapsedPercentage(
                  fourthWorkDays,
                  endDueDate
                )}%`}
                active
              />
              <ProgressBar
                procentage={`${
                  100 - calculateElapsedPercentage(fourthWorkDays, endDueDate)
                }%`}
              />
            </div>
            <div className="mt-2 pr-4">
              <Typography
                className={classNames([
                  'heading_semibold__16',
                  payrollsNotApproved &&
                    sixWorkDays <= new Date() &&
                    'color_red',
                ])}
              >
                Payroll ready for approval
              </Typography>
              <Typography
                className={classNames([
                  'text_medium__14 mt-2',
                  payrollsNotApproved &&
                    sixWorkDays <= new Date() &&
                    'color_red',
                ])}
              >
                Between {moment(fourthWorkDays).format('ddd')}{' '}
                {fourthWorkDays.getDate()} - {moment(endDueDate).format('ddd')}{' '}
                {endDueDate.getDate()}
              </Typography>
              <Typography className="text_light__12 color_grey mt-2">
                Payroll is ready for approval from{' '}
                {moment(fourthWorkDays).format('ddd')}{' '}
                {fourthWorkDays.getDate()}; non-approved payroll will
                automatically be approved after{' '}
                {moment(endDueDate).format('ddd')} {endDueDate.getDate()}
              </Typography>
              {!payrollsNotApproved &&
                !sixWorkDays <= new Date() &&
                calculateElapsedPercentage(endDueDate, weekDate) < 100 &&
                calculateElapsedPercentage(endDueDate, weekDate) > 0 && (
                  <Button
                    type="button"
                    size="xsmall"
                    style={{ height: '24px', width: 'fit-content' }}
                    priority="primary"
                    className="mt-3"
                    onClick={() => history.push('/pages/payrolls')}
                  >
                    Review & Approve
                  </Button>
                )}
              {payrollsNotApproved && sixWorkDays <= new Date() && (
                <Button
                  type="button"
                  size="xsmall"
                  style={{ height: '24px', width: 'fit-content' }}
                  priority="primary_dangerous"
                  className="mt-3"
                  onClick={() => history.push('/pages/payrolls')}
                >
                  Review & Approve
                </Button>
              )}
            </div>
          </div>
        </Spinner>
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center">
            <Icon
              icon={
                calculateElapsedPercentage(weekDate, cutOffDate) === 100
                  ? success
                  : emptyCircle
              }
            />
            <ProgressBar
              procentage={`${calculateElapsedPercentage(
                weekDate,
                cutOffDate
              )}%`}
              active
            />
            <ProgressBar
              procentage={`${
                100 - calculateElapsedPercentage(weekDate, cutOffDate)
              }%`}
            />
          </div>
          <div className="mt-2 pr-4">
            <Typography className="heading_semibold__16">
              Payment to you
            </Typography>
            <Typography className="text_medium__14 mt-2">
              {moment(weekDate).format('ddd')} {weekDate.getDate()} -{' '}
              {moment(cutOffDate).format('ddd')} {cutOffDate.getDate()}
            </Typography>
            <Typography className="text_light__12 color_grey mt-2">
              Payroll managers will review payrolls from day{' '}
              {moment(weekDate).format('ddd')} {weekDate.getDate()} -{' '}
              {moment(cutOffDate).format('ddd')} {cutOffDate.getDate()}. Your
              invoices will be created when payroll is approved.
            </Typography>
          </div>
        </div>
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center">
            <Icon
              icon={
                calculateElapsedPercentage(weekDate, cutOffDate) === 100 &&
                data?.filter((a) => {
                  return a.current.payrolls.some(
                    (payroll) => payroll.state !== 'approved_by_partner'
                  )
                })
                  ? success
                  : emptyCircle
              }
            />
          </div>
          <div className="mt-2 pr-4">
            <Typography className="heading_semibold__16">
              Payroll disbursement
            </Typography>
            <Typography className="text_medium__14 mt-2">
              {moment(cutOffDate).format('ddd')} {cutOffDate.getDate()} -{' '}
              {moment(currentMonthLastDate).format('ddd')}{' '}
              {currentMonthLastDate.getDate()}
            </Typography>
            <Typography className="text_light__12 color_grey mt-2">
              Please make sure employees receive salaries on time
            </Typography>
            {calculateElapsedPercentage(weekDate, cutOffDate) === 100 && (
              <Button
                data-testid="CalendarStepper-815E24"
                type="button"
                size="xsmall"
                style={{ height: '24px', width: 'fit-content' }}
                priority="primary"
                className="mt-3"
                onClick={() => history.push('/pages/payslips')}
              >
                Upload payslips
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CalendarStepper
