import { Api } from '@api/Api'
import { generateService } from '@core/utils'

export const getRegions = generateService(async (params) => {
  const { data } = await Api.get(`regions/?limit=1000`, {
    params,
  })

  return data
})
