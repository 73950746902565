import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import {
  ProbationResponse,
  ProbationType,
  ProbationTypes,
} from '@remoteam-front/types/legacy'
import { v4 } from 'uuid'

type Props = {
  data?: ProbationResponse
}

export const ProbationPeriodTable = ({ data }: Props) => {
  const mappedData = [
    {
      type: 'Fixed',
      isApplicable: data?.fixed_contract_is_applicable,
      probationType:
        data?.fixed_contract_is_applicable && data?.probation_type
          ? ProbationTypes[data?.probation_type]
          : '-',
      min:
        data?.probation_type ===
        ProbationType.SPECIFIC_TO_DURATION_OF_FIXED_CONTRACT
          ? data?.segments && data?.segments[0]?.probation_days
          : data?.fixed_contract_minimum_day,
      max:
        data?.probation_type ===
        ProbationType.SPECIFIC_TO_DURATION_OF_FIXED_CONTRACT
          ? data?.segments && data?.segments[1]?.probation_days
          : data?.fixed_contract_maximum_day,
      suggested:
        data?.probation_type ===
        ProbationType.SPECIFIC_TO_DURATION_OF_FIXED_CONTRACT
          ? null
          : data?.fixed_contract_suggested_day,
      note: data?.fixed_contract_note,
    },
    {
      type: 'Permanent',
      isApplicable: data?.permanent_contract_is_applicable,
      probationType: '-',
      min: data?.permanent_contract_minimum_day,
      max: data?.permanent_contract_maximum_day,
      suggested: data?.permanent_contract_suggested_day,
      note: data?.permanent_contract_note,
    },
  ]

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Contract type</TableCell>
          <TableCell align="left">Applicable</TableCell>
          <TableCell align="left">Type</TableCell>
          <TableCell align="left">Minimum</TableCell>
          <TableCell align="left">Maximum</TableCell>
          <TableCell align="left">Suggested</TableCell>
          <TableCell align="center">Note</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {mappedData?.map(
          ({
            isApplicable,
            max,
            min,
            note,
            suggested,
            type,
            probationType,
          }) => (
            <TableRow key={v4()}>
              <TableCell
                sx={{ minWidth: 150 }}
                align="left"
                component="th"
                scope="row"
              >
                {type}
              </TableCell>
              <TableCell align="left">
                {isApplicable ? (
                  <Chip label="Yes" color="success" />
                ) : (
                  <Chip label="No" />
                )}
              </TableCell>
              <TableCell align="left">{probationType}</TableCell>
              <TableCell align="left">{min ? `${min} days` : '-'}</TableCell>

              <TableCell align="left">{max ? `${max} days` : '-'}</TableCell>

              <TableCell align="left">
                {suggested ? `${suggested} days` : '-'}
              </TableCell>

              <TableCell
                align="left"
                sx={{ minWidth: '200px', wordBreak: 'break-all' }}
              >
                {note || '-'}
              </TableCell>
            </TableRow>
          )
        )}
      </TableBody>
    </Table>
  )
}
