import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import PropTypes from 'prop-types'
import React from 'react'
import {
  FaCheckCircle,
  FaExclamationCircle,
  FaExclamationTriangle,
  FaInfoCircle,
} from 'react-icons/fa'
import styled from 'styled-components'

const StyledButton = styled(Button)`
  border-radius: 4px !important;
`

const ToastWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 16px;
  border-radius: 4px;
  max-width: 300px;
  color: ${({ type }) => {
    switch (type) {
      case 'error':
        return '#721c24'
      case 'success':
        return '#155724'
      case 'warning':
        return '#856404'
      case 'info':
      default:
        return '#0c5460'
    }
  }};
`

const IconWrapper = styled.div`
  margin-bottom: 8px;
`

const Title = styled(Typography)`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
`

const Message = styled(Typography)`
  font-size: 16px;
  margin-bottom: 16px;
`

const ButtonGroup = styled.div`
  display: flex;
  gap: 16px;
`

const getIcon = (type) => {
  switch (type) {
    case 'error':
      return <FaExclamationCircle size={24} />
    case 'success':
      return <FaCheckCircle size={24} />
    case 'warning':
      return <FaExclamationTriangle size={24} />
    case 'info':
    default:
      return <FaInfoCircle size={24} />
  }
}

const ToastWithActions = ({ title, message, actions, type }) => (
  <ToastWrapper
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
    type={type}
  >
    <IconWrapper>{getIcon(type)}</IconWrapper>
    {title && <Title>{title}</Title>}
    {message && <Message>{message}</Message>}
    {actions && actions.length > 0 && (
      <ButtonGroup>
        {actions.map((action) => (
          <StyledButton
            size="small"
            key={action.label}
            variant={action.variant || 'primary'}
            onClick={action.onClick}
          >
            {action.label}
          </StyledButton>
        ))}
      </ButtonGroup>
    )}
  </ToastWrapper>
)

ToastWithActions.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      variant: PropTypes.string,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
  type: PropTypes.oneOf(['error', 'success', 'warning', 'info']),
}

ToastWithActions.defaultProps = {
  type: 'error',
}

export default ToastWithActions
