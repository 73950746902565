import { useApp } from '@core/context'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { WorkSchedule } from '@remoteam-front/types/legacy'
import { v4 } from 'uuid'

type Props = {
  data: WorkSchedule
}

export const WorkScheduleTable = ({ data }: Props) => {
  const { profile } = useApp() as any

  const rangeMax = (
    (data?.full_time_max_work_days_per_week || 0) *
    (data?.full_time_max_work_hours_per_day || 0)
  ).toFixed(2)
  const rangeMin = (
    (data?.full_time_min_work_days_per_week || 0) *
    (data?.full_time_min_work_hours_per_day || 0)
  ).toFixed(2)

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">Type</TableCell>
            <TableCell align="left">Min working days per week</TableCell>
            <TableCell align="left">Max working days per week</TableCell>
            <TableCell align="left">Min working hours per day</TableCell>
            <TableCell align="left">Max working hours per day</TableCell>
            <TableCell align="center">
              Range of working hours per week
            </TableCell>
            <TableCell align="center">
              Suggested Full-time work hour per week in{' '}
              {profile?.allowed_countries[0].name}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={v4()}>
            <TableCell
              sx={{ minWidth: 150 }}
              align="left"
              component="th"
              scope="row"
            >
              Full-time
            </TableCell>
            <TableCell align="left">
              {data?.full_time_min_work_days_per_week !== null
                ? `${data?.full_time_min_work_days_per_week} days`
                : '-'}
            </TableCell>
            <TableCell align="left">
              {data?.full_time_max_work_days_per_week !== null
                ? `${data?.full_time_max_work_days_per_week} days`
                : '-'}
            </TableCell>
            <TableCell align="left">
              {data?.full_time_min_work_hours_per_day !== null
                ? `${data?.full_time_min_work_hours_per_day.toFixed(2)} hours`
                : '-'}
            </TableCell>

            <TableCell align="left">
              {data?.full_time_max_work_hours_per_day !== null
                ? `${data?.full_time_max_work_hours_per_day.toFixed(2)} hours`
                : '-'}
            </TableCell>

            <TableCell align="left">
              {data?.full_time_specific_case
                ? `${rangeMin}-${rangeMax} hours`
                : `${data?.full_time_suggested_work_hours_per_week.toFixed(
                    2
                  )} hours`}
            </TableCell>

            <TableCell
              align="left"
              sx={{ minWidth: '200px', wordBreak: 'break-all' }}
            >
              {data?.full_time_suggested_work_hours_per_week
                ? `${data?.full_time_suggested_work_hours_per_week.toFixed(
                    2
                  )} hours`
                : '-'}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">Type</TableCell>
            <TableCell align="left">Min working hours per week</TableCell>
            <TableCell align="left">Max working hours per week</TableCell>
            <TableCell align="left">Range of working hours per week</TableCell>
            <TableCell align="left">
              Suggested Full-time work hour per week in{' '}
              {profile?.allowed_countries[0].name}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={v4()}>
            <TableCell
              sx={{ minWidth: 150 }}
              align="left"
              component="th"
              scope="row"
            >
              Part-time
            </TableCell>
            <TableCell align="left">
              {data?.part_time_min_work_hours_per_week !== null
                ? `${data?.part_time_min_work_hours_per_week.toFixed(2)} hours`
                : '-'}
            </TableCell>

            <TableCell align="left">
              {data?.part_time_max_work_hours_per_week !== null
                ? `${data?.part_time_max_work_hours_per_week.toFixed(2)} hours`
                : '-'}
            </TableCell>

            <TableCell align="left">
              {`${data?.part_time_min_work_hours_per_week.toFixed(
                2
              )} - ${data?.part_time_max_work_hours_per_week.toFixed(2)} hours`}
            </TableCell>

            <TableCell
              align="left"
              sx={{ minWidth: '200px', wordBreak: 'break-all' }}
            >
              {data?.part_time_suggested_work_hours_per_week
                ? `${data?.part_time_suggested_work_hours_per_week.toFixed(
                    2
                  )} hours`
                : '-'}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  )
}
