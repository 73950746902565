import NotFound from '@atoms/NotFound/NotFound'
import Select from '@atoms/Select/Select'
import CustomTable from '@atoms/Table/CustomTable'
import Typography from '@atoms/Typography/Typography'
import { AppContext, useApp } from '@core/context'
import { parseHolidays } from '@pages/time-off/parse'
import { getRegions } from '@services/regions.service'
import classNames from 'classnames'
import React, { useContext, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { styled } from 'styled-components'

import { getHolidays } from '../../services/holidays.service'

const StyledModal = styled(Modal)`
  background: rgba(0, 0, 0, 0.5);
  z-index: 2000;

  .dialog-holidays {
    width: 600px;
  }
`

const generateArrayOfYears = () => {
  const max = new Date().getFullYear()
  const min = max + 1
  const years = []

  // eslint-disable-next-line no-plusplus
  for (let i = max; i <= min; i++) {
    years.push(i)
  }
  return years
}

export default ({ title = 'Public Holidays', onClose }) => {
  const { countriesActive } = useContext(AppContext)
  const { profile } = useApp()
  const [selectedCountry, setSelectedCountry] = useState({
    value: profile?.legal_address?.living_country?.id,
    label: profile?.legal_address?.living_country?.name,
  })
  const [selectedRegionId, setSelectedRegionId] = useState(null)
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())

  const { isLoading, data } = useQuery(
    ['holidays', selectedCountry, selectedYear, selectedRegionId],
    {
      queryFn: () => {
        const params = {
          limit: 1000,
          country_id: selectedCountry.value,
          year: selectedYear,
        }
        if (selectedCountry.hasRegions) params.regions = selectedRegionId
        return getHolidays(params)
      },
      enabled: !!selectedCountry,
    }
  )

  const regionsQuery = useQuery([getRegions.key, selectedCountry], {
    queryFn: () => getRegions.fetch({ country: selectedCountry.value }),
    enabled: !!selectedCountry?.hasRegions,
  })

  const onChangeCountry = (option) => {
    setSelectedCountry(option)
    setSelectedRegionId(null)
  }
  const onChangeYear = (option) => {
    setSelectedYear(option.value)
  }

  const handleRegionChange = ({ value }) => {
    setSelectedRegionId(value)
  }

  const fields = [
    {
      title: 'Date',
      key: 'date',
      type: 'string',
      maxWidth: 120,
      minWidth: 120,
      width: 120,
    },
    {
      title: 'Day',
      key: 'day',
      type: 'string',
      maxWidth: 120,
      minWidth: 120,
      width: 120,
    },
    {
      title: 'Name',
      key: 'name',
      type: 'textMedium',
      maxWidth: 260,
      minWidth: 260,
      width: 260,
    },
  ]

  return (
    <StyledModal
      show
      centered
      size="lg"
      className="dialog-modal"
      dialogClassName="dialog-holidays"
      onHide={onClose}
    >
      <Modal.Header closeButton className="mb-0">
        <Typography className="heading_semibold__24">{title}</Typography>
      </Modal.Header>
      <Modal.Body className="px-3 pt-2">
        <div className="row">
          <div className="col-6">
            <div className="remo-form-input">
              <Select
                data-testid="ListHolidaysModal-5FAD91"
                options={countriesActive.map((country) => ({
                  value: country.id,
                  label: country.name,
                  hasRegions: country.has_regions,
                }))}
                name="select_country"
                id="select_country"
                placeholder="e.g United Kingdom"
                onChange={onChangeCountry}
                value={selectedCountry}
                label="Country"
              />
            </div>
          </div>

          <div className="col-6">
            <div className="remo-form-input">
              <Select
                data-testid="ListHolidaysModal-DF27BF"
                options={generateArrayOfYears().map((year) => ({
                  value: year,
                  label: year,
                }))}
                name="year"
                id="year"
                placeholder="e.g 2022"
                onChange={onChangeYear}
                value={selectedYear}
                label="Year"
              />
            </div>
          </div>
          {selectedCountry?.hasRegions && regionsQuery.isSuccess && (
            <div className="col-12">
              <div className="remo-form-input">
                <Select
                  data-testid="ListHolidaysModal-Region"
                  label="Region"
                  options={regionsQuery.data.results.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))}
                  id="region"
                  placeholder=""
                  onChange={handleRegionChange}
                  value={selectedRegionId}
                />
              </div>
            </div>
          )}
        </div>
        {!data ? (
          <NotFound
            loading={isLoading}
            className="holiday-not-found"
            title="Please select a country"
            description="You will see the list of the public holidays in this country"
          />
        ) : (
          <div
            className={classNames([
              'row holidays-table',
              data?.results?.length > 1 && 'holidays-table-height',
            ])}
          >
            {data && data?.results?.length >= 1 ? (
              <CustomTable
                loading={isLoading}
                fields={fields}
                data={parseHolidays(data.results)}
              />
            ) : (
              <NotFound
                loading={isLoading}
                className="holiday-not-found"
                title="We have not found any days off for this country and year"
              />
            )}
          </div>
        )}
      </Modal.Body>
    </StyledModal>
  )
}
