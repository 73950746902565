import { useEffect, useState } from 'react'

// Load asynchronously but ONLY after we set listener
const loadKompassifyScript = () => {
  const script = document.createElement('script')
  script.src =
    'https://player.kompassify.com/kompassifyPlayer.js?uuid=130650b2-f53d-48a6-9717-0a11ac0c7036'
  script.type = 'text/javascript'
  script.id = 'kompassious'
  script.async = true
  document.head.appendChild(script)
}
export const useKompassify = (profile) => {
  const [isCompassifyReady, setIsCompassifyReady] = useState(false)

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const handleKompassifyReady = (event) => {
      if (event.data.TYPE === 'KOMPASSIFY_BOOT_LOADER_IS_READY') {
        setIsCompassifyReady(true)
      }
    }

    if (window.kompassifyPlayerActive) {
      setIsCompassifyReady(true)
    } else {
      window.addEventListener('message', handleKompassifyReady)
      loadKompassifyScript()
    }

    return () => {
      window.removeEventListener('message', handleKompassifyReady)
    }
  }, [])
  useEffect(() => {
    if (!isCompassifyReady || !profile?.id || !profile?.name) {
      return
    }
    window.kommpassifyIdUser(profile?.id)
    window.kompassifyTemplating.addTemplateVariable(
      'client',
      `${profile?.name}`
    )
  }, [isCompassifyReady, profile?.id, profile?.name])
  return isCompassifyReady
}
