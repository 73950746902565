import { useEffect } from 'react'

const addUserToSegment = (segmentName) => {
  window.postMessage({
    TYPE: 'KOMPASSIFY_SEGMENTATION_ADD_USER_TO_SEGMENT',
    segmentName,
  })
}

export const useKompassifySegments = (hasKompassifyInit, partnerCountry) => {
  // Add default segment
  useEffect(() => {
    if (!hasKompassifyInit) {
      return
    }
    addUserToSegment('Partner')
  }, [hasKompassifyInit])

  // Add segment based on country
  useEffect(() => {
    if (!hasKompassifyInit || !partnerCountry) {
      return
    }
    addUserToSegment(`Partner-${partnerCountry}`)
  }, [hasKompassifyInit, partnerCountry])
}
