import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { deleteIcon, edit, view } from '@core/icons/icons'
import { capitalizeFirstLetter } from '@core/utils'
import { mapCurrency } from '@remoteam-front/utils/currency'
import moment from 'moment'
import React from 'react'
import { AiOutlineSync } from 'react-icons/ai'

export const payrollsField = (
  onClickView,
  onClickEdit,
  handleDelete,
  onCancel
) => [
  {
    title: 'Team member',
    key: 'name',
    type: 'custom',
    maxWidth: 270,
    minWidth: 270,
    width: 270,
    render: (item) => {
      const showIcon = item.is_recurrent
      return (
        <div className="d-flex align-items-center">
          <Typography className="text_medium__14">{item.name}</Typography>
          {showIcon && (
            <div className="ml-2">
              <AiOutlineSync />
            </div>
          )}
        </div>
      )
    },
  },
  {
    title: 'Submitted',
    key: 'created_at',
    type: 'string',
    maxWidth: 130,
    minWidth: 130,
    width: 130,
  },
  {
    title: 'Company',
    key: 'company',
    type: 'string',
    maxWidth: 136,
    minWidth: 136,
    width: 136,
  },
  {
    title: 'Amount',
    key: 'amount',
    type: 'custom',
    maxWidth: 100,
    minWidth: 100,
    width: 100,
    render: (item) => {
      return (
        <div className="d-flex flex-column">
          <Typography className="text_regular__14 mb-2">
            {item.amount_in_partner_currency || '-'}
            {mapCurrency(item.partner_currency)}{' '}
          </Typography>
          <Typography className="text_regular__14 color_grey">
            {item.amount || '-'}
            {mapCurrency(item.currency)}{' '}
          </Typography>
        </div>
      )
    },
  },
  {
    title: 'Expected payout',
    key: 'payout_month',
    type: 'custom',
    maxWidth: 100,
    minWidth: 100,
    width: 100,
    render: (item) => {
      return (
        <div className="d-flex align-items-center">
          {item.status === 'Cancelled' ? (
            <Typography className="text_regular__14 color_red mr-2">
              Cancelled
            </Typography>
          ) : (
            <Typography className="text_regular__14 mr-2">
              {item?.payout_date
                ? moment(item.payout_date).format('MMMM YYYY')
                : '-'}
            </Typography>
          )}
        </div>
      )
    },
  },
  {
    title: 'Total amount',
    key: 'total_amount',
    type: 'string',
    maxWidth: 136,
    minWidth: 136,
    width: 136,
  },
  {
    title: 'Status',
    key: 'status',
    type: 'custom',
    maxWidth: 120,
    minWidth: 120,
    width: 120,
    render: (item) => {
      switch (item.status.toLowerCase()) {
        case 'created':
          return <div className="status status_active_ap">Created</div>
        case 'approved_by_company':
          return (
            <div className="status status_active_ap">Approved by company</div>
          )
        case 'pending':
          return <div className="status status_warning_ap">Pending</div>
        case 'moved_to_next_month':
          return (
            <div className="status status_warning_ap">Moved to next month</div>
          )
        case 'awaiting_payment':
          return (
            <div className="status status_warning_ap">Awaiting payment</div>
          )
        case 'cancelled':
          return <div className="status status_error_ap">Cancelled</div>
        case 'canceled':
          return <div className="status status_error_ap">Cancelled</div>
        case 'rejected':
          return <div className="status status_error_ap">Rejected</div>
        case 'deleted':
          return <div className="status status_error_ap">Deleted</div>
        case 'paid':
          return <div className="status status_success_ap">Paid</div>
        case 'complete':
          return <div className="status status_success_ap">Complete</div>
        default:
          return (
            <div className="status status_default">
              {capitalizeFirstLetter(item.status).replace('_', ' ')}
            </div>
          )
      }
    },
  },
  {
    title: 'Action',
    key: 'custom',
    type: 'custom',
    maxWidth: 250,
    minWidth: 250,
    width: 250,
    render: (row) => {
      return (
        <div className="d-flex align-items-center">
          <Button
            onClick={() => onClickView(row)}
            className="text_light__12 px-2 pl-0 ml-2"
            priority="secondary"
            size="small"
            style={{ height: '32px' }}
          >
            <Icon fill="none" icon={view} className="mr-2" />
            View
          </Button>
          {!row.is_recurrent &&
            row.status !== 'Cancelled' &&
            (row.assigned_payroll_state === 'need_changes_from_partner' ||
              row.assigned_payroll_state === 'payroll is not assigned' ||
              row.assigned_payroll_state === 'created') && (
              <Button
                onClick={() => handleDelete(row.id)}
                className="text_light__12 px-2 pl-0 ml-2"
                priority="secondary"
                size="small"
                style={{ height: '32px' }}
              >
                <Icon fill="none" icon={deleteIcon} className="mr-2" />
                Delete
              </Button>
            )}
          {row.is_recurrent &&
            row.status !== 'Cancelled' &&
            (row.assigned_payroll_state === 'need_changes_from_partner' ||
              row.assigned_payroll_state === 'payroll is not assigned' ||
              row.assigned_payroll_state === 'created') && (
              <Button
                onClick={() => onCancel(row.id)}
                className="text_light__12 px-2 pl-0 ml-2"
                priority="secondary"
                size="small"
                style={{ height: '32px' }}
              >
                Cancel
              </Button>
            )}
          {row.status !== 'Cancelled' &&
            (row.assigned_payroll_state === 'need_changes_from_partner' ||
              row.assigned_payroll_state === 'payroll is not assigned' ||
              row.assigned_payroll_state === 'created') && (
              <Button
                onClick={() => onClickEdit(row)}
                className="text_light__12 px-2 pl-0 ml-2"
                priority="secondary"
                size="small"
                style={{ height: '32px' }}
              >
                <Icon fill="none" icon={edit} className="mr-2" />
                Edit
              </Button>
            )}
        </div>
      )
    },
  },
]
