import Icon from '@atoms/Icon/Icon'
import { KompassifyNotificationsWrapper } from '@components/kompassify-notification-wrapper'
import { KompassifyNotificationBadge } from '@components/sidebar/KompassifyNotificationBadge'
import { AppContext } from '@core/context'
import {
  connect,
  costCalculator,
  dots,
  externalLinkIcon,
  notificationsIcon,
  support,
  user,
} from '@core/icons/icons'
import { getCostCalculatorList } from '@services/cost-calculation.service'
import classNames from 'classnames'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import { useBoolean } from 'usehooks-ts'
import { v4 } from 'uuid'

import { AuthModal } from './auth-modal/auth-modal.component'
import styles from './sidebar.module.scss'
import { UserAccounts } from './user-accounts/user-accounts.component'

export const Sidebar = ({
  firstName,
  lastName,
  items,
  profilePagePath,
  onLogout,
  linkedAccounts,
  sessions,
}) => {
  const { profile, userProfile } = useContext(AppContext)
  const isProfileRoute = useRouteMatch({ path: profilePagePath })
  const menuRef = useRef()
  const menu = useBoolean(false)
  const history = useHistory()
  const signInModalOpen = useBoolean(false)
  const [prefillEmail, setPrefillEmail] = useState(undefined)

  const handleAddAcount = () => {
    setPrefillEmail(undefined)
    signInModalOpen.setTrue()
  }

  const handleClickSignIn = (email) => {
    setPrefillEmail(email)
    signInModalOpen.setTrue()
  }

  const costEmployerExistQuery = useQuery([getCostCalculatorList.key], () =>
    getCostCalculatorList.fetch({
      country_id: profile.allowed_countries && profile.allowed_countries[0]?.id,
      ignore_region: true,
      partner: {
        id: profile.partner_profile_id,
        name: profile.name,
        user_id: userProfile.id,
      },
    })
  )

  useEffect(() => {
    const handler = (e) => {
      if (menuRef.current && menuRef.current.contains(e.target)) {
        return
      }
      menu.setFalse()
    }

    window.addEventListener('click', handler)

    return () => {
      window.removeEventListener('close', handler)
    }
  }, [menu])

  return (
    <>
      <div className={styles.root}>
        <div className={styles.links}>
          <div className={styles.logo}>
            <img src="/assets/icons/logo_2.0.svg" alt="logo" />
          </div>

          {items
            .filter(({ hidden }) => !hidden)
            .map(({ title, children }) => (
              <div key={v4()} className={styles.section}>
                {title && <div className={styles.section__title}>{title}</div>}

                {children
                  .filter(({ hidden }) => !hidden)
                  .map(({ url, icon, count, name }) => (
                    <NavLink
                      key={v4()}
                      to={`/pages/${url}`}
                      className={styles.section__item}
                      activeClassName={styles.section__item_active}
                    >
                      <div>
                        <Icon icon={icon} />
                      </div>

                      <div title={name}>{name}</div>
                      {!!count && (
                        <div className="flex-grow-1 d-flex justify-content-end">
                          <div className={styles['section__item-badge']}>
                            {count}
                          </div>
                        </div>
                      )}
                    </NavLink>
                  ))}
              </div>
            ))}

          <div
            className={classNames([styles.section, styles['footer-section']])}
          >
            {(costEmployerExistQuery.data?.results?.length && (
              <NavLink
                to="/pages/cost-calculator"
                activeClassName={styles.section__item_active}
                className={styles.section__item}
              >
                <div>
                  <Icon icon={costCalculator} />
                </div>
                <div>Cost calculator</div>
              </NavLink>
            )) ||
              ''}
            <KompassifyNotificationsWrapper>
              <div className={styles.section__item}>
                <Icon icon={notificationsIcon} />
                <div>Notifications</div>
                <KompassifyNotificationBadge />
              </div>
            </KompassifyNotificationsWrapper>
            <a
              target="_blank"
              className={styles.section__item}
              href="https://remofirst.atlassian.net/wiki/external/YTQ4YWExNjE3ZDIzNGU3ODk2NWNhNjY2NjUwNWQ3MTQ"
              rel="noreferrer"
            >
              <div>
                <Icon icon={support} />
              </div>
              <div>Platform guides</div>
              <span className={styles.section__item__external}>
                <Icon icon={externalLinkIcon} viewBox="0 0 20 20" />
              </span>
            </a>
          </div>
        </div>

        <div className={styles['profile-section']}>
          <div
            className="d-flex align-items-center"
            ref={menuRef}
            role="button"
            aria-hidden
            onClick={menu.toggle}
          >
            <div className={classNames(styles['profile-section__item'])}>
              <div
                className={classNames({
                  [styles['profile-section__item_active']]: isProfileRoute,
                })}
              >
                <Icon icon={user} />
              </div>
              <div
                className={classNames([styles['profile-section__item-label']], {
                  [styles['profile-section__item_active']]: isProfileRoute,
                })}
              >{`${firstName} ${lastName}`}</div>
            </div>

            <div style={{ paddingRight: 12 }}>
              <Icon icon={dots} />
            </div>
          </div>
          <div
            className={classNames([styles['sidebar-menu']], {
              'd-none': !menu.value,
            })}
          >
            <UserAccounts
              onClickSignIn={handleClickSignIn}
              linkedAccounts={linkedAccounts}
              sessions={sessions}
            />
            <div
              className={classNames([styles['sidebar-menu__item']])}
              role="button"
              aria-hidden
              onClick={handleAddAcount}
            >
              Add account
            </div>
            <div
              className={classNames([styles['sidebar-menu__item']])}
              role="button"
              aria-hidden
              onClick={onLogout}
            >
              Sign out of all accounts
            </div>
          </div>
        </div>
      </div>

      <AuthModal
        prefillEmail={prefillEmail}
        isOpen={signInModalOpen.value}
        onClose={signInModalOpen.setFalse}
      />
    </>
  )
}
